@import "_variable.scss";

.about-us-page {
  scroll-behavior: smooth;
  background-color: #1E1E26;

  .tab-section {
    position: sticky;
    top: 100px;
    width: 100%;
    background-color: white;
    border-radius: 20px 20px 0 0;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
    z-index: 1000;
  }
  @media screen and (max-width: 400px){
  .open-700w-14s-22h
  {
    font-size: 12px;
  }
} 
  .mobile-page-title-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

  }

  .banner-section {
    @media screen and (max-width: 1024px) {

    }
    @media screen and (min-width: 1024px) {
      min-height: 50vh;
      padding-left: 80px;
    }

    .banner-bg {
      @media screen and (max-width: 1024px) {
        background-image: url($background-cdn + "assets/images/about-us-banner-bg-mobile.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        height: 109px;
      }
      @media screen and (min-width: 1024px) {
        background-image: url($background-cdn + "assets/images/about-us-banner-bg-desktop.png");
        background-position: right bottom -60px;
        background-repeat: no-repeat;
        background-size: contain;
      }

    }
  }

}

//welcome-page
.welcome,
.green-vision {
  .mobile-image {
    width: 100%;
  }

  .mobile-title,
  .desktop-title {
    font-family: 'Ubuntu', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    color: #1e1e26;
    border-left: 6px solid #22bbb0;
    border-radius: 1px;
    padding-left: 16px;
  }

  .desktop-title {
    font-size: 32px;
  }

  .welcome-data {
    p {
      font-family: 'Open Sans', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #4b4b51;
    }

    .welcome-data-dark-content {
      color: black;
      font-weight: 600;
    }
  }
}

@media (min-width: 1024px) {
  .welcome,
  .green-vision {
    padding: 0 50px;
    gap: 50px;
  }
  .welcome-data {
    max-width: 587px;
    width: 100%;

    p {
      font-size: 16px !important;
      line-height: 24px !important;
    }
  }
}
// ----------


// -----------------

@media (min-width: 1280px) {
  .welcome,
  .green-vision {
    padding: 0 80px;
    gap: 130px;
  }
}

// vision page
.green-vision {
  .mobile-title,
  .desktop-title {
    border-left: none;
    padding-left: 0;
  }
}

//   nuegotrivia page\
.nuego-trivia-part {
  background-image: url($background-cdn + "assets/images/triviaMobileImage.png");
  background-position: bottom;
  background-size: cover;
  background-repeat: no-repeat;
  height: 437px;

  .trivia-title {
    font-family: 'Ubuntu', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    color: #1e1e26;
  }

  .nuego-trivia-content {
    p {
      font-family: 'Open Sans', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: #4b4b51;
    }

    .nuego-sub-head {
      margin-top: -14px;
    }
  }
}

@media (min-width: 1024px) {
  .nuego-trivia-part {
    background-image: url($background-cdn + "assets/images/triviaDesktopImage.png");
    height: 299px;
  }
  .trivia-title {
    font-size: 32px !important;
  }
  .nuego-trivia-part {
    padding: 0 50px;
    gap: 50px;
  }
  .nuego-trivia-content {
    max-width: 930px;
    width: 100%;

    p {
      font-size: 16px !important;
      line-height: 24px;
    }
  }
}

@media (min-width: 1280px) {
  .nuego-trivia-part {
    padding: 0 80px;
    gap: 120px;
  }
}

//Our Leader page
.our-leaders {
  background: #f8f8f9;

  .our-leader-title {
    font-family: 'Ubuntu', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    color: #1e1e26;
  }

  .our-leaders-part {
    gap: 13px;
    flex-wrap: wrap;
    justify-content: space-evenly;
    

    .our-leader-box {
      //margin: auto;
      max-width: 165px;
      border: 1px solid #e8e8e9;
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.12);
      border-radius: 16px;
      gap: 13px;
      position: relative;
    }

    img {
      width: 100%;
      height: 131px;
      object-fit: cover;
      border-radius: 12px;
    }

    .lindedIn {
      position: absolute;
      right: 20px;
      width: 25px;
      height: 25px;
      background-color: white;
      z-index: 10;
      @media (min-width: 1024px) {
        top: 20px;
      }

      img {
        width: auto;
        height: auto;
      }
    }

    .leaders-name {
      font-family: 'Open Sans', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: #1e1e26;
    }

    .leaders-position {
      font-family: 'Open Sans', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      font-size: 14px;
     @media (max-width:1024px){
        line-height: normal;
      }
      color: #78787d;
      } 
  }
}

@media (min-width: 1024px) {
  .our-leaders {
    .our-leader-title {
      font-size: 32px;
    }

    .our-leaders-part {
      gap: 32px;

      .our-leader-box {
        max-width: 218px;
        margin: 0;
        position: relative;
      }

      img {
        width: 100%;
        height: 200px;
        object-fit: cover;

      }

      // .leaders-name {
      //   font-size: 20px;
      // }

      
    }
  }
}

// ourTeam
.our-team-member {
  background: #f8f8f9;

  .our-leader-title {
    font-family: 'Ubuntu', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    color: #1e1e26;
  }

  .teamMemberImage {
    min-width: 145px;
    height: 145px;
    object-fit: cover;
  }


  .our-teams {
    padding: 0 0 0 20px;

    .nuego-team-carousel {
      display: flex;
      justify-content: center;
    }


    //
    .swiper-horizontal > .swiper-pagination-bullets,
    .swiper-pagination-bullets.swiper-pagination-horizontal,
    .swiper-pagination-custom,
    .swiper-pagination-fraction {
      display: none;
    }

    .swiper-button-next,
    .swiper-button-prev {
      width: 64px;
      height: 64px;
    }

    .swiper-button-next:after,
    .swiper-button-prev:after {
      display: none;
    }
  }

  .nuego-name {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: #1e1e26;
  }

  .teamCard {
    position: relative;
    border: 1px solid #e8e8e9;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.12);
    border-radius: 16px;
  }

  .lindedIn {
    position: absolute;
    right: 30px;
    top: 5px;
    @media (min-width: 560px) {
      right: 60px;
    }
  }

  .nuego-position {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #4b4b51;
  }

  img {
    background: #ffffff;
    //border-radius: 16px;
  }
}

@media (min-width: 1024px) {
  .our-team-member {
    .our-leader-title {
      font-size: 32px;
    }

    .our-teams {
      padding: 0 50px;
      position: relative;
    }

    .control-icon {
      display: flex;
      position: absolute;
      right: 2px;
      top: -80px;
    }

    .carousel-control-prev-sec {
      padding: 10px 16px;
      background: #ffffff;
      box-shadow: 0 4px 14px rgba(0, 0, 0, 0.09);
      border-radius: 8px;
      border: none;
    }

    .swiper-button-next {
      background-image: none !important;
    }

    .swiper-button-prev {
      background-image: none !important;
    }

    .teamCard {
      border: 1px solid #e8e8e9;
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.12);
      border-radius: 16px;
    }

    .teamMemberImage {
      max-width: 278px;
      height: 276px;
      object-fit: cover;
    }

    .lindedIn {
      position: absolute;
      top: 20px;
      right: 20px;
      width: 25px;
      height: 25px;
      background-color: white;

      z-index: 999;

      img {
        width: auto;
        height: auto;
      }
    }

    img {
      max-width: 278px;
      width: 100%;
    }

    .nuego-name,
    .nuego-position {
      font-size: 12px;
      line-height: 5px;
    }
  }
}

@media (min-width: 1280px) {
  .our-teams {
    margin: auto !important;
    padding: 0 80px;
    max-width: 1350px;
    width: 100%;

    .teamCard {
      border: 1px solid #e8e8e9;
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.12);
      border-radius: 16px;
    }


    .teamMemberImage {
      width: 278px;
      height: 356px;
      object-fit: cover;
    }

    .lindedIn {
      position: absolute;
      top: 20px;
      right: 20px;
      width: 25px;
      height: 25px;
      background-color: white;


      z-index: 999;

      img {
        width: auto;
        height: auto;
      }
    }

    .nuego-name,
    .nuego-position {
      font-size: 16px;
      line-height: 8px;
    }

    .swiper-button-prev {
      left: 94% !important;
      transform: translate(-94%, 0);
    }
  }
}
@media screen and  (max-width: 1023px) {
 .about-us-page .tab-section {
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    //max-width: fit-content;
    z-index: 1000; 
    background-color: #fff;  
  }
}



