.charging-india{
    background-color: #FFFF;
    margin:  0 0 60px 0;
    .main-container{
        width: 89%;
        display: flex;
        flex-direction: column;
        margin: auto;

        .image-content{
            display: flex;
            flex-direction: row;
            gap: 80px;
            justify-content: space-between;
            width: 72%;
            margin: auto;
            @media screen and (max-width:991px) {
                display: flex;
                flex-direction: column;
                width: 89%;
                gap: 16px;
                margin: auto;
            }
            .imagediv{
                width: auto;
                height: auto;
               
                @media screen and (max-width:991px) {
                    width: 250px;
                    height: 194px;
                    margin: auto;

                    img{
                        width: 100%;
                        height: 100%;
                    }
                }
            }
            .contentdiv{
                display: flex;
                flex-direction: column;
                gap: 0px;
                width: fit-content;
                height: auto;
                @media screen and (max-width:991px) {
                    width: 89%;
                    height: auto;   
                    margin: auto; 
                }
                .heading{
                    font-family: 'Ubuntu',sans-serif;
                    font-size: 32px;
                    font-weight: 700;
                    line-height: 48px;
                    text-align: left;
                    color: #1E1E26;


                    @media screen and (max-width:991px){
                        font-size: 20px;
                        font-weight: 700;
                        line-height: 48px;
                        text-align: center;
                    }

                }
                .charge{
                    font-family: 'Open Sans',sans-serif;
                    font-size: 18px;
                    font-weight: 600;
                    line-height: 28px;
                    text-align: left;
                    color: #1E1E26;
                    @media screen and (max-width:991px){
                        font-size: 14px;
                        font-weight: 500;
                        line-height: 19.6px;
                        text-align: center;
                    }
                }
                .records{
                    display: flex;
                    flex-direction: row;
                    justify-content: start;
                    gap: 16px;
                    align-items: center;
                    @media screen and (max-width:991px) {
                    justify-content: center;
                    }
                    .record-img{
                        height: 66px;
                        width: auto;
                        img{
                        padding: 5px;
                        }
                    }

                }
            }
        }
        .special-things {
            display: flex;
            flex-direction: row;
            gap: 24px;
            margin: 32px auto 0px;
            background: #E5F9F7;
            border-radius: 8px;
            padding: 32px;
            @media screen and (max-width: 1024px) {
              display:flex;
              flex-direction: column;
              
            }
            .icon-parent-banner{
                display: flex;
                flex-direction: row;
                gap: 16px;
                align-items: center;

                .downloadIcon1{
                    width: 56px;
                    height: 56px;
                }
                .details-width {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    height: 62px;
                    width: auto;
                .head{
                    font-family: 'Open Sans', sans-serif;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 32px;
                    color: #1E1E26;
                    margin-bottom: 0px;
                }
                .ans{
                    font-family: 'Open Sans',sans-serif;
                    font-style: normal;
                    font-size: 16px;
                    font-weight: 700;
                    line-height: 32px;
                    text-align: left;
                    margin-bottom: 0px;
                    width: auto;
    
                }
                }
            }
      
           
          }
    }
}