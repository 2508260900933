@import "../../assets/styles/font_color_mixins";

.review-card-component {
  //max-width: 786px;

  .vertical-dashed-line {
    position: absolute;
    left: -30px;
    top: 0;
    border: none;
    border-left-style: dotted;
    border-left-color: #d2d2d4;
    border-left-width: 3px;
    //border-left:1px dotted #D2D2D4;
    color: #d2d2d4;
    background-color: transparent;
    height: 100%;
    z-index: 1;
    //width:1px;
  }

  .main-content-area {
    z-index: 50;
    position: relative;
    background: #ffffff;
    border-radius: 20px;
    border: 1px solid #d2d2d4;
    box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.12);
    //overflow: hidden;
  }

  .pointing-icon {
    position: relative;
    //left: -16px;
    //top: 16px;
    z-index: 2;
  }

  .route-data {
    // border-bottom-style: dashed;
    // border-bottom-color: #d2d2d4;
    // border-bottom-width: 3px;
  }

  .review-content-area {
    z-index: 10;
    margin-top: -32px;
    background: linear-gradient(
                    90deg,
                    rgba(34, 188, 177, 0.1) 0%,
                    rgba(143, 233, 176, 0.1) 100%
    );
    /* Black / 20 */

    border: 1px solid #d2d2d4;
    border-radius: 0px 0px 20px 20px;
  }

  .right-circle-inner {
    position: relative;

    background: linear-gradient(
                    90deg,
                    rgba(210, 210, 212, 0.9803921569) -28%,
                    #ffffff 38%
    ) padding-box,
    linear-gradient(90deg, #b9b9b9 50%, #ffffff00 50%) border-box;
    //linear-gradient(90deg, #D2D2D4, #ffffff) padding-box,
    //linear-gradient(90deg, #D2D2D4, #ffffff) border-box;
    border-radius: 100%;
    border: 1px solid transparent;
  }

  .left-circle-inner {
    position: relative;

    background: linear-gradient(
                    273deg,
                    rgba(210, 210, 212, 0.9803921569) -28%,
                    #ffffff 38%
    ) padding-box,
    linear-gradient(270deg, #b9b9b9 50%, #ffffff00 50%) border-box;
    border-radius: 100%;
    border: 1px solid transparent;
  }

  @media screen and (max-width: 1024px) {
    .right-circle {
      position: absolute;
      bottom: 50%;
      transform: translate(0, 50%);
      right: -32px;

      .right-circle-inner {
        width: 24px;
        height: 24px;
      }
    }
    .left-circle {
      position: absolute;
      bottom: 50%;
      transform: translate(0, 50%);
      left: -32px;

      .left-circle-inner {
        width: 24px;
        height: 24px;
      }
    }
  }
  @media screen and (min-width: 1024px) {
    .right-circle {
      position: absolute;
      bottom: 50%;
      transform: translate(0, 50%);
      right: -41px;

      .right-circle-inner {
        width: 32px;
        height: 32px;
      }
    }
    .left-circle {
      position: absolute;
      bottom: 50%;
      transform: translate(0, 50%);
      left: -41px;

      .left-circle-inner {
        width: 32px;
        height: 32px;
      }
    }
  }

}

.review-card-v2-component {
  max-width: 786px;

  .pnr-area {
    background: #22bbb0;
    border-radius: 20px 20px 0px 0px;
    padding-bottom: 70px;
  }

  .vertical-dashed-line {
    position: absolute;
    left: -14px;
    top: 0;
    border: none;
    border-left-style: dotted;
    border-left-color: #d2d2d4;
    border-left-width: 3px;
    //border-left:1px dotted #D2D2D4;
    color: #d2d2d4;
    background-color: transparent;
    height: 100%;
    z-index: 1;
    //width:1px;
  }

  .main-content-area {
    margin-top: -44px;
    z-index: 50;
    position: relative;
    background: #ffffff;
    border-radius: 20px;
    border: 1px solid #d2d2d4;
    box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.12);
    //overflow: hidden;
  }

  .pointing-icon {
    position: relative;
    //left: -16px;
    //top: 16px;
    z-index: 2;
  }

  .route-data {
    border-bottom-style: dashed;
    border-bottom-color: #d2d2d4;
    border-bottom-width: 3px;
  }

  .review-content-area {
    z-index: 10;
    margin-top: -32px;
    background: linear-gradient(
                    90deg,
                    rgba(34, 188, 177, 0.1) 0%,
                    rgba(143, 233, 176, 0.1) 100%
    );
    /* Black / 20 */

    border: 1px solid #d2d2d4;
    border-radius: 0px 0px 20px 20px;
  }

  .right-circle-inner {
    position: relative;

    background: linear-gradient(
                    90deg,
                    rgba(210, 210, 212, 0.9803921569) -28%,
                    #ffffff 38%
    ) padding-box,
    linear-gradient(90deg, #b9b9b9 50%, #ffffff00 50%) border-box;
    //linear-gradient(90deg, #D2D2D4, #ffffff) padding-box,
    //linear-gradient(90deg, #D2D2D4, #ffffff) border-box;
    border-radius: 100%;
    border: 1px solid transparent;
  }

  .book-cab {
    gap: 8px;

    p {
      margin: 0;
      color: #4B4B51;
    }

    @media(min-width: 1024px) {
      p {
        font-weight: 600;
      }
    }
  }

  .left-circle-inner {
    position: relative;

    background: linear-gradient(
                    273deg,
                    rgba(210, 210, 212, 0.9803921569) -28%,
                    #ffffff 38%
    ) padding-box,
    linear-gradient(270deg, #b9b9b9 50%, #ffffff00 50%) border-box;
    border-radius: 100%;
    border: 1px solid transparent;
  }

  @media screen and (max-width: 1024px) {
    .right-circle {
      position: absolute;
      bottom: 50%;
      transform: translate(0, 50%);
      right: -12px;

      .right-circle-inner {
        width: 24px;
        height: 24px;
      }
    }
    .left-circle {
      position: absolute;
      bottom: 50%;
      transform: translate(0, 50%);
      left: -12px;

      .left-circle-inner {
        width: 24px;
        height: 24px;
      }
    }
  }
  @media screen and (min-width: 1024px) {
    .right-circle {
      position: absolute;
      bottom: 50%;
      transform: translate(0, 50%);
      //right: -41px;
      right: -17px;

      .right-circle-inner {
        width: 32px;
        height: 32px;
      }
    }
    .left-circle {
      position: absolute;
      bottom: 50%;
      transform: translate(0, 50%);
      //left: -41px;
      left: -17px;

      .left-circle-inner {
        width: 32px;
        height: 32px;
      }
    }
  }
}

.route-data {
  .place-images {
    height: 40px;
    width: 40px;
    border: 1.5px solid #f0faf9;
    border-radius: 133.333px;
  }
}

//mdb modal

.review-card-boarding-dropping-modal {
  @media (min-width: 1024px) {
    .auth-modal .modal {
      left: 50%;
      transform: translate(-50%, -50%);
      // width: 75vw;
      height: -moz-fit-content;
      height: fit-content;
      top: 50%;
      z-index: 100000;
      background-color: white;
      max-width: 597px;
      width: 100%;
      height: 458px;
      background: #ffffff;
      border-radius: 20px;
    }
    .modal-dialog-centered {
      align-items: flex-start !important;
    }
    .modal-dialog .modal-content {
      box-shadow: none !important;
      top:120px;
    }
    .mdb-data {
      padding-top: 40px;
      padding-left: 40px;
      padding-right: 40px;

      .board-drop-title-sec {
        padding-bottom: 32px;

        h1 {
          font-family: "Ubuntu";
          font-style: normal;
          font-weight: 700;
          font-size: 24px;
          line-height: 36px;
          color: #1e1e26;
        }

        img {
          height: 16px;
          width: 16px;
        }
      }

      .pickup-drop-datas {
        width: 95%;

        h1 {
          font-family: "Open Sans";
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          color: #1e1e26;
        }

        h2 {
          font-family: "Open Sans";
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          color: #78787d;
          padding-bottom: 10px;
        }

        p {
          font-family: "Open Sans";
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 22px;
          color: #00a095;
        }

        .pickup-drop-images {
          border-radius: 8px;
          width: 104px;
          height: 80px;
        }

        .map-view-images {
          width: 18px;
          height: 20px;
        }
      }
    }
  }
}

// bottomTray mobile
.bottom-tray-mobile-component .header-section {
  padding: 0 !important;
  border-bottom: none !important;
}

.bottom-tray-mobile {
  .bottom-tray-board-drop {
    padding: 16px;
  }

  .bottom-tray-mobile-component .content-section {
    padding-top: 0px !important;
  }

  .modal-header {
    border-bottom: none;
  }

  .board-drop-title-sec {
    h1 {
      font-family: "Ubuntu";
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 28px;
      color: #1e1e26;
    }
  }

  hr {
    margin-top: 10px !important;
  }

  .pickup-drop-datas {
    width: 95%;

    h1 {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: #1e1e26;
    }

    h2 {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #78787d;
      padding-bottom: 10px;
    }

    p {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 22px;
      color: #00a095;
    }

    .pickup-drop-images {
      border-radius: 8px;
      width: 96px;
      height: 80px;
    }

    .map-view-images {
      width: 18px;
      height: 20px;
    }
  }
}


.review-card-fare-detail-modal{
  .mdb-data{
    padding:40px
  }
    
}
