@import "../../assets/styles/font_color_mixins";

.navbar-component {
  z-index: 100 !important;
  height: fit-content;
  @media screen and (max-width: 1023px) {
    padding: 24px 12px;

  }
  background-color: $black-1E1E26;
  padding: 28px 80px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 20;


@media screen and (max-width: 768px) {
  &::before {
    content: "";
    position: absolute;
    right: 0;
    left:0;  
    background-color: transparent;
    bottom: -50px;
    height: 50px;
    border-top-right-radius: 25px;
    border-top-left-radius: 25px;
    box-shadow: 0 -25px 0 0 #1E1E26;
  }

  }

   
  .margin-left-18 {
    margin-left: 18px;
  }

  .margin-left-14 {
    margin-left: 14px;
  }

  .margin-right-14 {
    margin-right: 14px;
  }

  .profile-card-toggler {
    position: relative;
  }

  .profile-card {
    position: absolute;
    right: 0;
    top: 200%;
    padding: 24px;
    z-index: 100;
    background: white;
    height: 236px;
    width: 331px;
    border-radius: 12px;

    p {
      flex-wrap: nowrap;
    }

  }

  .booking-nav{
    position:relative
  }

  .booking-card-toggler{
    position:absolute;
    top: 0;
    right: 0;
    z-index: 999999;
  }

  .booking-card {
    position: absolute;
    right: 21%;
    top: 86%;
    padding: 24px;
    z-index: 100;
    background: white;
    height: 236px;
    width: 331px;
    border-radius: 12px;

    p {
      flex-wrap: nowrap;

    }
  }
  .booking-card-local{
    right: 16%;
  }

  .change-arrow-white {
    // filter #22BBB0 to white
    filter: invert(0%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(120%) contrast(100%);

  }

  .profile-picture {
    width: 32px;
    height: 32px;
    border-radius: 100%;
  }

  .booking-card-wrapper,
  .profile-card-wrapper{
    .backdrop{
      position: fixed;
      top:0;
      left:0;
      right:0;
      bottom:0;
      background-color: transparent;
      z-index: 5;
    }
  }

  .mobile-menu {
    max-height: 0;
    transition: max-height 0.3s ease-out;  
  }
  
  .mobile-menu.active {
    max-height: 500px;
  }

 
.navbar1 {
  background: #444;
  transition: all .6s;
  backface-visibility: hidden;
  box-shadow: 0 2px 5px 1px rgba(51, 51, 51, 0.5);
}

.navbar-dark1 .navbar-nav1 {
  font-size: 15px;
  color: #fff;
  text-transform: uppercase;
}

.navbar-dark1 .navbar-nav1 .nav-link1 {
  color: #fff;
  color: rgba(255, 255, 255, 0.8);
  border: 0;
}

.navbar-dark1 .navbar-nav1 .nav-link1:hover {
  color: #fff;
}

.navbar-dark1 .navbar-nav1 .nav-link1:focus {
  color: #fff;
  outline: 0;
}

.navbar-dark1 .navbar-toggler1 {
  border-color: #444;
}

.navbar-dark1 .navbar-toggler1:focus {
  background: none;
  outline: 0;
}

.navbar-dark1 .navbar-toggler1 span {
  display: block;
  width: 30px;
  height: 2px;
  margin: 6px auto;
  background: #fff;
  transition: all .6s cubic-bezier(0.250, 0.100, 0.250, 1.000);
}

.navbar-dark1 .navbar-toggler1:hover span,
.navbar-dark1 .navbar-toggler1:focus span {
  background: #ccc;
}

.navbar-dark1 .navbar-toggler1[aria-expanded="true"] span:first-of-type {
  transform: rotate(45deg) translate(6px, 6px);
}

.navbar-dark1 .navbar-toggler1[aria-expanded="true"] span:nth-of-type(2) {
  opacity: 0;
}

.navbar-dark1 .navbar-toggler1[aria-expanded="true"] span:last-of-type {
  transform: rotate(-45deg) translate(5px, -5px);
}

.navbar-dark1 .navbar-toggler1[aria-expanded="false"] span {
  transform: none;
  opacity: 1;
}

.navbar-brand1 {
  width: 162px;
  height: 44px;
  background: url(../../assets/icons/close.svg) left center no-repeat;
  border: 0;
  text-indent: -99999px;
}

.navbar-dark1 .navbar-toggler1 span {
  display: block;
  width: 30px;
  height: 2px;
  margin: 6px auto;
  background: #fff;
  transition: all .6s cubic-bezier(0.250, 0.100, 0.250, 1.000);
}

.navbar-dark1 .navbar-toggler1[aria-expanded="true"] span:first-of-type {
  transform: rotate(45deg) translate(6px, 6px);
}

.navbar-dark1 .navbar-toggler1[aria-expanded="true"] span:nth-of-type(2) {
  opacity: 0;
}

.navbar-dark1 .navbar-toggler1[aria-expanded="true"] span:last-of-type {
  transform: rotate(-45deg) translate(5px, -5px);
}

.navbar-dark1 .navbar-toggler1[aria-expanded="false"] span {
  transform: none;
  opacity: 1;
}









:root {
	--mainColor: #29335c;
	--mainColorLight: #5767aa;
	--secondaryColor: #db2b39;
	--textColor: #eee;
}

header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	background-color: var(--mainColor);
	color: var(--textColor);
}

nav a {
	color: var(--textColor);
	text-decoration: none;
}

nav a:hover {
	color: var(--secondaryColor);
}

header .nav-btn {
  padding: 0px;
	cursor: pointer;
	background: transparent;
	border: none;
	outline: none;
	color: var(--textColor);
	visibility: hidden;
	opacity: 0;
}

header div,
nav {
	display: flex;
	align-items: center;
}

@media only screen and (max-width: 1024px) {
	header .nav-btn {
		visibility: visible;
		opacity: 1;
	}

	header nav {
		position: fixed;
		top: -100vh;
		left: 0;
		height: 100%;
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: 1.5rem;
		background-color: var(--mainColor);
		transition: 1s;
	}

	header .responsive_nav {
    background: #FFF;
		transform: translateY(100vh);
    overflow-y: scroll;
	}

	nav a {
		color: #1E1E26;

font-family: 'Open Sans',sans-serif;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 24px;
	}

  .option{
   flex-direction: column;
   width: 80%;
   height: 70%;
   align-items: start;
   margin-top: 50px;
  }



  .roll-down {
    display: flex;
    flex-direction: column;
    transition: max-height 2.0s ease-out;
    align-items: start;
  }
  
  .sub{
    color: rgba(0, 0, 0, 0.70);

      font-family: 'Open Sans',sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 48px;
      letter-spacing: -0.32px;
      text-transform: capitalize;
      width: 100%;

      display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .new1{
    width: 81%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}
}