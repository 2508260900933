@import '../../assets/styles/font_color_mixins';

.fnb{


  // .fnb-illustration-illustration{
  //   width: 100%;
  // }

  .submit-button-fnb{
    background-color: $teal-22BBB0;
    color: white;
    padding: 16px;
    width: 373px;
    border-radius: 40px;
    margin-bottom: 10%;

    @media screen and (max-width: 768px) {
      width: 80%;
    }
    
  }

  @media screen and (max-width: 1023px) {
    .navbar-wrapper {
      padding-bottom: 24px;
      background: #1E1E26;

    }
    .white-fold-padding {
      padding: 21px 16px 0
    }
    .button-border {
      border: 1px solid #1E1E26;
      border-radius: 100px;
      padding: 12px 24px;
      width: fit-content;

      p {
        white-space: nowrap;
      }
    }

  }
  @media screen and (min-width: 1024px) {
    .navbar-wrapper {
      padding-bottom: 24px;
      background: #1E1E26;

    }
    //.white-fold-wrapper{
    //  max-width: 1144px;
    //  display: flex;
    //  justify-content: center;
    //}
    .white-fold-padding {

      display: flex;
      justify-content: center;
      padding: 64px 32px 0;

      .inner-div {
        width: 100%;
        max-width: 1400px;
      }
    }


    .button-border {
      border: 1px solid #7AD6D0;
      border-radius: 100px;
      padding: 12px 24px;
      width: fit-content;

      p {
        white-space: nowrap;
      }
    }

  }
}

.fnbpage{
  .main-content{
    @media screen and (min-width: 1024px) {
      width: 80%;
      margin:auto;
      margin-top: 10%;
      
    }
  }

  .btn{
    background: #22bbb0;
    border-radius: 40px;
    box-shadow: 0 4px 12px rgba(0,0,0,.08);
    color: #fff;
    font-family: Open Sans, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    max-width: 343px;
    padding: 16px 0;
    width: 100%;
    @media screen and (max-width:768px){
      margin-top: 25px;
    }
  }

  .fnb-image{
   width: 100%;
   height:100%;
   border-radius: 5%;
   object-fit: fill;
  }

}