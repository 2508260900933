@import "../../assets/styles/font_color_mixins";
@import "_variable.scss";

.home-page {

  .flexible-container {

    width: 89%;
    // max-width: 1266px; /* Limit width to 1266px */
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 24px;
    margin: auto;
  
    @media screen and (max-width: 1365px) {
      width: 89%;
      margin: auto;
      margin-top: 24px ;
    }
  
    @media screen and (min-width: 1406px) {
      width: 100%; 
      max-width: 1266px; 
      margin: auto; 
      margin-top: 24px;
    }
  
    @media screen and (max-width: 1024px) {
      width: 90%;
      margin: auto;
    }
  }
  

  .mini_hr_line {
    background: #E8E8E8;
    border-radius: 10px;
    width: 56px;
    height: 4px;
    position: absolute;
    //transform: translate(-50%, -50%);
    top: 0;
    left: calc(50% - 28px);
  }



  .join {
    background: #F0FAF9;
    border-radius: 24px;
    //border-top-right-radius:24px;
    //border-top-left-radius: 24px;
    padding: 68px 146px 58px 119px;
    position: relative;

    .join_mail {
      width: 140px;
    }

    .title {
      margin-bottom: 40px;
      font-family: 'Ubuntu', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      line-height: 48px;
      color: #1E1E26;
    }

    .half_sun {
      position: absolute;
      top: 0;
      right: 0;
      width: 111px;
      border-top-right-radius: 24px;
    }

    .email-id {
      border: 1px solid rgba(30, 30, 38, 0.1);
      border-radius: 12px;
      padding: 20px 60px;
      margin-right: 24px;
      width: 480px;
      background-image: url($background-cdn + "assets/images/Message.png");
      background-repeat: no-repeat;
      background-size: 24px 24px;
      background-position: 20px;

      &:focus-visible {
        outline: none;
        border: 1px solid rgba(30, 30, 38, 0.1);
      }
    }

    .Signup {
      border: none;
      outline: none;
      background: #22BBB0;
      border-radius: 100px;
      padding: 16px 89px;
      font-family: 'Open Sans', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #FFFFFF;

      &:focus-visible {
        border: none;
        outline: none;
      }
    }
  }

  .MobileTopBus {
    background-color: white;
    padding: 20px 0px 20px;
    background-image: url($background-cdn + "assets/images/Title_mWeb%20(1)%20(1).9017e24b6355964a8c74.webp");
    background-size: 100% 100%;
    //position: sticky;
    //top: 78px;
    //overflow: hidden;
    position: relative;
    .w-48px {
      width: 48px;
    }

    .Rectangle {
      width: 56px;
      position: absolute;
      left: 50%;
      bottom: -48px;
      transform: translate(-50%, 0);
    }

    .Bus_illustration {
      width: 132px;
      height: 86px;
      position: absolute;
      right: 0;
    }

    .title {
      width: 343px;
      padding: 16px 16px 16px 16px;
      font-family: 'Ubuntu', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 32px;
      color:  #1E1E26;
      text-align: center;
      margin: auto;

    }

    .search {
      padding: 0 0 90px 0;
      .enter-place {
        background: #FFFFFF;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 16px;
        margin: 0 16px !important;
        padding: 20px;

        .start_location {
          padding-bottom: 16px;
          border: none;
          border-bottom: 1px solid rgba(30, 30, 38, 0.12);

          &:focus-visible {
            border: none;
            border-bottom: 1px solid rgba(30, 30, 38, 0.12);
            outline: none;
          }
        }

        .end_location {
          padding-top: 16px;
          border: none;
          outline: none;

          &:focus-visible {
            border: none;
            outline: none;
          }
        }

        .pace_left_icon {
          display: flex;
          align-items: center;
        }

        .mb_input_left_icon {
          width: 16px;
          height: 72px;
        }

        .flip {
          width: 56px;
          height: 56px;
        }
      }
    }

    .date {
      background: #FFFFFF;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 12px;
      display: flex;
      padding: 10px 8px 10px 8px;
      position: relative;
      margin: 16px;

      .today, .Tomorrow {
        font-family: 'Open Sans', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 22px;
        color: rgba(30, 30, 38, 0.6);
        width: 48px;
        height: 48px;
        border-radius: 8px;
        text-align: center;

      }

      .selected-date {
        color: #FFFFFF;
        background-color: #22BCB1;
      }

      .Calendar-icon {
        position: relative;
        left: 15px;
        width: 32px;
        height: 32px;
        font-family: 'Open Sans', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #1E1E26;
        transform: translateX(-50%);
      }
      

      .fast-date-select {
        display: flex;
        //margin-left: 16px;
        gap: 4px;
        //height: 24px;
        height: 100%;
        padding: 2px 12px 2px 12px;
      }

      .date-value {
        margin-left: 30px;
      }

      .calender-parent-div{
        color: #FFFFFF;
        background-color: #22BCB1;
        border-radius: 8px;
        padding: 0px 5px;
      }

      .calender {
        width: 100%;
        padding-right: 0px;
        margin-left: 6px;
        border: none;
        border-right: 1.5px solid #1E1E26;
        outline: none;
      }
      .calender-active {
        width: 100%;
        padding-right: 0px;
        margin-left: 6px;
        border: none;
        border-right: 1.5px solid #1E1E26;
        outline: none;
        color: #ffffff;
      }
    }

    .seat-count {
      position: relative;
      display: flex;
      background: #FFFFFF;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 12px;
      padding: 20px 16px;
      margin: 16px;
      color: #1E1E2699;

      .current-count {
        font-family: 'Open Sans', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #1E1E26;
      }

      .inner-count {
        display: flex;
        margin-left: 48px;
        gap: 12px;
        position: absolute;
        right: 26px;
      }

      .seat_count {

        width: 24px;
        margin-right: 12px;
      }

      .count-plus, .count-sub {
        img {
          width: 24px;

        }
      }

    }

    .searct-btn {
      position: relative;
      background: #29BDB5;
      border-radius: 100px;
      padding: 16px 25px 0px 25px;
      height: 56px;
      font-family: 'Open Sans', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #FFFFFF;
      // margin: 16px 16px 0px 16px;
      width: fit-content;
      margin: auto;

      .Search {
        margin-right: 12px;
        width: 20px;
      }
    }

    .btn-start-search {
      border: 1px solid rgba(30, 30, 38, 0.1);
      box-shadow: 0 4px 14px rgba(0, 0, 0, 0.04);
      border-radius: 12px;
      margin: 16px;
      padding: 20px 18px;
      background: white;
      position: absolute;
      width: calc(100% - 32px);
      bottom: -48px;
      font-family: 'Open Sans', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: rgba(30, 30, 38, 0.6);

      .search {
        margin-right: 14px;
        width: 20px;
        height: 20px;
      }
    }

    .search-input-wrapper {
      position: relative;

      .search-suggestion-wrapper {
        position: absolute;
        top: 100%;
        left: 0;
        border-radius: 12px;
        background-color: white;
        padding: 24px 24px 0;
        box-shadow: 0 4px 16px rgba(0, 0, 0, 0.12);
        width: fit-content;
        max-height: 291px;
        height: fit-content;
        overflow-y: auto;
        z-index: 99;
      }
    }

    .mwebsearchBarBusImage{
      position:absolute;
      bottom:0px;
      right: 20%;
    }

  }

  .top-layer {
    background: #FFFFFF;
    border-radius: 20px 20px 0 0;
    //padding: 20px;
    //padding-top:490px ;
    position: relative;
  }

  .flexible-container{
    @media screen and (max-width:1366) {
      width: 89%;
      display : flex;
      align-items: center;
      flex-direction: column;
      gap: 24px
    }
  
    @media screen and (min-width:1366) {
      max-width: 1366;
      margin: auto;
      display : flex;
      flex-direction: column;
      gap: 24px
    }
  }

  .submit-button {
    background-color: $teal-22BBB0;
    color: white;
    padding: 8px 16px;
    border-radius: 40px;
  }


  .recent-search-section {

    
      margin: 24px 80px;

    .recent-search-wrapper {
      display: flex;
      overflow-x: auto;
      width: auto;
    }

    .recent-search-item {
      background: #FFFFFF;
      border: 1px solid #E9F8F7;
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
      border-radius: 12px;
      border-left: 8px solid #7AD6D0;
    }

    // hide scrollbar for recent-search-wrapper
    .recent-search-wrapper::-webkit-scrollbar {
      display: none;
    }

    .recent-search-wrapper::-webkit-scrollbar-track {
      display: none;
    }

    .recent-search-wrapper::-webkit-scrollbar-thumb {
      display: none;
    }

    .recent-search-wrapper::-webkit-scrollbar-button {
      display: none;
    }

    .recent-search-wrapper::-webkit-scrollbar-corner {
      display: none;
    }

    .recent-search-wrapper::-webkit-resizer {
      display: none;
    }

    .recent-search-wrapper {
      -ms-overflow-style: none;
      scrollbar-width: none;
    }
  
  @media(max-width:991px) {
    margin: 24px 24px;
  }
  }
}

// components
.homepage {
  .search-suggestion {
    padding: 24px 24px;
    background-color: white;
    border-radius: 12px;
  }


}


.change-color {
  color: #00A095;
}


@media (min-width: 1024px) {
  .our-routes {
    .our-routes-title {
      font-size: 24px;
      line-height: 36px;
    }

    .our-routes-sub-title {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
    }

    .location-image {
      width: 23px;
      height: 26px;
    }

    .our-routes-places {
      padding: 0 15px 0 80px;
      position: relative;
      .swiper-slide:not(.swiper-slide-active) {
        filter: blur(0px);
        transform: scale(1);
      }
    }

    .control-icon {
      display: flex;
      position: absolute;
      right: 50px;
      top: -66px;
    }

    .carousel-control-prev-sec {
      padding: 10px 16px;
      background: #ffffff;
      box-shadow: 0 4px 14px rgba(0, 0, 0, 0.09);
      border-radius: 8px;
      border: none;
    }

    .swiper-button-next {
      background-image: none !important;
    }

    .swiper-button-prev {
      background-image: none !important;
    }

    .teamCard {
      border: 1px solid #e8e8e9;
      border-radius: 16px;
      max-width: 285px;
    }

    .teamMemberImage {
      max-width: 260px;
      height: 276px;
      object-fit: cover;
    }
    .routes-down{
      gap:9px;
    }
    .routes-name ,.from-route {
      font-weight: 500;
      font-size: 20px;
      line-height: 32px;
    }

    .trending-routes{
      top: 20px;
      right:20px;
      font-weight: 700;
      font-size: 10px;
      line-height: 14px;
    }
  }
}

@media (min-width: 1280px) {
  .our-routes-places {
    margin: auto !important;
    padding: 0 80px;
    //max-width: 1350px;
    width: 100%;
    .teamMemberImage {
      max-width: 260px;
    }

    .swiper-button-prev {
      left: 94% !important;
      transform: translate(-94%, 0);
    }
  }
}

.view-t-c{
color: var(--white-100, #FFF);
text-align: right;
font-family: "Open Sans" , sans-serif;
font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: 28px; /* 155.556% */
margin-bottom: 0;
}

.p-x-4{
  @media screen and (min-width: 1023px) {
    padding-left: 4rem !important;
  }
}


.scrollToTopArrow{
  display: inline-flex;
  padding: 3px 5px 5px 3px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: #22BBB0;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
  position: fixed;
  right: 20px;
  bottom: 20px;
  // width: 24px;
  // height: 24px;
  cursor: pointer;
  z-index: 99999999;
  img{
    display: flex;
    width: 24px;
    height: 24px;
    padding: 3px 4px;
    justify-content: center;
    align-items: center;
  }
}


.EmissionAndReferalslider{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media screen and (min-width: 1024px) {
    width: 100%;
    height: auto;
    margin: 24px auto;
   }
  @media screen and (max-width: 1023px) {
    width: 100%;
    height: 217px;
    padding-left: 16px;
  }
  .swiper{
    width:100%;
    .swiper-wrapper{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap:2%;
      .swiper-slide:first-child{
        @media screen and (min-width: 1024px) {
        width:53% !important;
        margin-right: 0px !important;
        }
        @media screen and (max-width: 1023px) {
          width:95% !important;
          margin-right: 0px !important;
        }
        
      }
      .swiper-slide:nth-child(2){
        @media screen and (min-width: 1024px) {
        width:45% !important;
        margin-right: 0px !important;
        }
        @media screen and (max-width: 1023px) {
          width:95% !important;
          margin-right: 0px !important;
        }
        
      }

    }
  }

}


.icon-bar {
  width: 30px;
  height: auto;
  padding: 12px 0px 0px 0px;
  right: 0px;
  gap: 15px;
  border-radius: 4px 0px 0px 4px;
  opacity: 1;
  background: #22BBB0;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 5;
  .a {
    width: 20px;
    height: 20px;
    padding: 0px 0px 0.24px 0px;
    gap: 0px;
    opacity: 0px;
    display: block;
    text-align: center;
    padding: 16px;
    transition: all 0.3s ease;
    color: white;
    font-size: 20px;
    z-index: 9999;

    
  }
  .iconImage{
    width: 20px;
    height: 20px;
  }
  .icon-bar a:hover {
    background-color: #000;
  }
}

.flexible-container{
  @media screen and (max-width:1306) {
    width: 89%;
    display : flex;
    align-items: center;
    flex-direction: column;
    gap: 24px
  }

  @media screen and (min-width:1366) {
    max-width: 1366;
    margin: auto;
    display : flex;
    flex-direction: column;
    gap: 24px
  }
}


