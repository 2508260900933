.twitterContainer{
  align-items: center;
  height: 250px;
  overflow-y: scroll;
  .twitter-timeline{
    display : flex;
    width:100%;
    overflow-x: scroll;
    scrollbar-width:thin;
  }
  & ::-webkit-scrollbar {
    width: 6px;
    height: auto;
    padding-left: 16px;
  }

  /* Track */
  & ::-webkit-scrollbar-track {
    padding-left: 16px;
    box-shadow: inset 0 0 5px transparent;
    border-radius: 10px;
  }

  /* Handle */
  & ::-webkit-scrollbar-thumb {
    background: #D2D2D450;
    border-radius: 14px;

  }

  /* Handle on hover */
  & ::-webkit-scrollbar-thumb:hover,
  & ::-webkit-scrollbar-thumb:active {
    background: #D2D2D4;
  }
}