@import './src/assets/styles/font_color_mixins';

.bottom-tray-mobile-component {
  position: relative;
  z-index: 10000000;

  .modal-header {
    padding: 0 !important;
    .closing-icon {
      position: absolute;
      top: -60px;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      cursor: pointer;
      background-color: black;
      border-radius: 100%;
      justify-content: center;
      align-items: center;
  
    }
  }

  .modal-dialog {
    position: relative;
  }

  .modal-content {
    max-height: 70vh;
    height: fit-content;
    min-height: 20vh;
    overflow: visible;
    //overflow-y: auto;
  }

  .header-section {
    padding: 8px 0 16px;
    border-bottom: 1px solid #E8E8E9;
  }

  .modal-content {
    box-shadow: 0px -4px 20px rgba(0, 0, 0, 0.05);
    border-radius: 20px 20px 0px 0px !important;
  }

  .modal-dialog {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0;
    height: fit-content;
    box-shadow: 0px -4px 20px rgba(0, 0, 0, 0.05);
    border-radius: 20px 20px 0px 0px !important;
    max-width: 100vw;
  }

  .content-section {
    max-height: 70vh;
    height: fit-content;
    min-height: 20vh;
    overflow-y: auto;
  }



  .terms-condition-details {

    .title {
      text-align: center;
      font-style: normal;
      font-family: 'Open Sans', serif;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: #1E1E26;
      padding-top: 40px;
      @media screen and (max-width: 1023px) {
        padding-top: 20px;
        font-size: 26px;
      }
    }

    table, th, td {
      border: 2px solid black;
      text-align: center !important;
    }

    table {
      margin-top: 10px;
    }

    th {
      font-family: 'Open Sans', sans-serif;

      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: #1E1E26;
      margin-bottom: 8px;
    }

    h2 {
      font-weight: 700;
      font-size: 40px;
      line-height: 56px;
      color: #1E1E26;
      @media screen and (max-width: 1023px) {
        font-size: 26px;
      }
    }

    h3 {
      font-family: 'Open Sans', sans-serif;
      text-align: justify;
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      line-height: 48px;
      color: #1E1E26;
      margin-bottom: 16px;
      @media screen and (max-width: 1023px) {
        font-size: 24px;
        line-height: 44px;
      }
    }

    h4 {
      font-family: 'Open Sans', sans-serif;
      text-align: justify;
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 36px;
      color: #1E1E26;
      margin-bottom: 8px;
      @media screen and (max-width: 1023px) {
        font-size: 20px;
        line-height: 28px;
      }
    }

    p, li, td {
      font-family: 'Open Sans', sans-serif;
      text-align: justify;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #78787D;
      @media screen and (max-width: 1023px) {
        font-size: 14px;
        line-height: 22px;
      }
    }

    @media screen and (max-width: 1023px) {
      padding: 16px;
      th, td {
        font-size: 12px;
      }

    }

  }
}