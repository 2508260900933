$font-family_1: "Open Sans", sans-serif;
$font-family_2: "Ubuntu", sans-serif;

.change-booking-page {
  .main-content {
    .title {
      font-style: normal;
      font-weight: 700;
      font-size: 40px;
      line-height: 56px;
      color: #1e1e26;
      padding-top: 40px;
      @media screen and (max-width: 1023px) {
        padding-top: 20px;
        font-size: 26px;
      }
    }
    .booking-trip-part {
      margin-top: 72px;
      margin-bottom: 170px;
      img {
        width: 218px;
        height: 218px;
      }
      h2 {
        font-family: $font-family_2;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 32px;
        text-align: center;
        color: #1e1e26;
        margin-top: 30px;
      }
      p {
        font-family: $font-family_1;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        text-align: center;
        color: rgba(45, 44, 44, 0.6);
      }
    }
    .bookig-trip-state {
      padding: 24px 16px 0px 16px;
      display: flex;
      gap: 8px;
      .inactive-trip {
        font-family: $font-family_1;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #78787d;
        background: #ffffff;
        border: 1px solid #d2d2d4;
        border-radius: 12px;
        text-align: center;
        max-width: 176px;
        width: 100%;
        padding: 12px 0;
      }
      .active-trip {
        font-family: $font-family_1;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #00a095;
        background: #e9f8f7;
        border: 1.5px solid #22bbb0;
        border-radius: 12px;
        text-align: center;
        max-width: 176px;
        width: 100%;
        padding: 12px 0;
      }
    }

    button {
      font-family: $font-family_1;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #ffffff;
      padding: 12px 0;
      max-width: 343px;
      width: 100%;
      background: #22bbb0;
      border-radius: 100px;
      margin-bottom: 24px;
      white-space: nowrap;
    }
  }
}

.row {
  margin-right: 0px !important;
  margin-left: 0px !important;
}

@media (min-width: 1024px) {
  .change-booking-page {
    .main-content {
      .modal .modal-fluid .modal-content {
        width: 65%;
        margin: auto;
        border-radius: 20px;
      }
      .desktop-booking-trip-part {
        margin-top: 40px;
        .cross-image {
          width: 14px;
          height: 14px;
          margin-right: 30px;
        }
        h1 {
          font-family: $font-family_2;
          font-style: normal;
          font-weight: 700;
          font-size: 24px;
          line-height: 36px;
          color: #1e1e26;
          padding-left: 30px;
        }
        .desktop-booking-trip {
          padding: 32px 30px 0px 30px;
          display: flex;
          gap: 16px;
          .inactive-trip {
            font-family: $font-family_1;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color: #78787d;
            background: #ffffff;
            border: 1px solid #d2d2d4;
            border-radius: 12px;
            text-align: center;
            max-width: 176px;
            width: 100%;
            padding: 16px 0;
            .btn-image {
              margin-right: 10px;
            }
          }
          .active-trip {
            font-family: $font-family_1;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color: #00a095;
            background: #e9f8f7;
            border: 1.5px solid #22bbb0;
            border-radius: 12px;
            text-align: center;
            max-width: 176px;
            width: 100%;
            padding: 16px 0;
            .btn-image {
              margin-right: 10px;
            }
          }
        }
      }
      .booking-trip-part {
        margin-top: 36px;
        margin-bottom: 0px;
        img {
          width: 240px;
          height: 240px;
          margin: auto;
          text-align: center;
        }
        h2 {
          font-family: $font-family_2;
          font-style: normal;
          font-weight: 700;
          font-size: 24px;
          line-height: 36px;
          color: #1e1e26;
        }
        p {
          font-family: $font-family_1;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          color: #78787d;
        }
      }
      button {
        max-width: 378px;
        width: 100%;
        padding: 16px 141px;
        margin-bottom: 40px;
        margin-top: 32px;
      }
    }
  }
}

@media (min-width: 1280px) {
  .change-booking-page {
    .main-content {
      max-width: 1280px;
      width: 100%;
      margin: auto;
    }
  }
}
