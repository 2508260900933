@import '../../assets/styles/font_color_mixins.scss';

$font-family_1: 'Open Sans', sans-serif;
$font-family_2: "Ubuntu", sans-serif;

.ticket-details-page {

  .back-arrow {
    position: absolute;
    top: 50%;
    left: 16px;
    transform: translateY(-50%);
  }

  .outline-button {
    background: #FFFFFF;
    border: 1.5px solid #22BBB0;
    border-radius: 40px;
    padding: 10px 16px;
    height: fit-content;
  }

  .toolbox-list-box {
    background: #1E1E26;
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.08);
    border-radius: 100px;
  }


  @media screen and (max-width: 1023px) {
    .navbar-wrapper {
      padding-bottom: 24px;
      background: #1E1E26;

    }
    .white-fold-padding {
      padding: 21px 16px 0
    }
    .button-border {
      border: 1px solid #1E1E26;
      border-radius: 100px;
      padding: 12px 24px;
      width: fit-content;

      p {
        white-space: nowrap;
      }
    }

  }
  @media screen and (min-width: 1024px) {
    .navbar-wrapper {
      padding-bottom: 24px;
      background: #1E1E26;

    }
    .navigation-tab {
      background: #1E1E26;
      box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.08);
      border-radius: 100px;
      padding: 16px 24px;
    }
    .white-fold-padding {

      display: flex;
      justify-content: center;
      padding: 64px 32px 0;

      .inner-div {
        width: 100%;
        max-width: 1400px;
      }
    }
    .button-border {
      border: 1px solid #7AD6D0;
      border-radius: 100px;
      padding: 12px 24px;
      width: fit-content;

      p {
        white-space: nowrap;
      }
    }

  }

}

.bottom-nav {
  background-color: #1E1E26;
  box-shadow: 0px -4px 12px rgba(0, 0, 0, 0.08);
  border-radius: 16px 16px 0px 0px;
}

.refund-status-ticket-detail {
  @media screen and (max-width: 1024px) {
    padding: 0 24px 24px;
    gap: 24px;
  }
  @media screen and (min-width: 1024px) {
    border: 1px solid #D2D2D4;
    border-radius: 16px;
    padding: 24px;
    gap: 24px;
  }

  .card {
    background: #F8F8F9;
    border-radius: 12px;
    padding: 24px;

    .illustration {
      // media query max-width 1024px
      @media screen and (max-width: 1024px) {
        width: 48px;
        height: 48px;
      }
      // media query min-width 1024px
      @media screen and (min-width: 1024px) {
        width: 69px;
        height: 69px;
      }

    }
  }

}

.upcoming-accordion-ticket-detail {
  .key-elements {
    color: #4B4B51;
  }

  & ::marker {
    color: #4B4B51 !important;
    background-color: #4B4B51 !important;
  }

  .receive-ticket-card {
    @media screen and (max-width: 1024px) {
      background: transparent;
    }
    @media screen and (min-width: 1024px) {
      background: #F8F8F9;
    }

    border-radius: 16px;
    padding: 16px;
  }

  .media-box {
    background: #FFFFFF;
    padding: 10px 16px;
    border: 1px solid #D2D2D4;
    border-radius: 12px;

    p {
      margin-bottom: 0;
    }
  }
}

.completed-feedback-ticket-detail {
  textarea {
    padding: 16px;
  }

  @media screen and (max-width: 1024px) {

    .completed-trip-details {
      background: #1e1e26;

      .completed-trip-banner {
        padding: 20px 99px 0 24px;
        gap: 63px;

        img {
          height: 14px;
          width: 16px;
          left: 4px;
        }
      }

      .completed-trip-details-heading {
        font-family: $font-family_1;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #ffffff;
      }

      .positive-comment {
        font-family: $font-family_1;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        text-align: center;
        color: rgba(255, 255, 255, 0.8);
      }

      .positive-comment {
        span {
          font-weight: 800;
          color: white;
          font-size: 16px;
        }
      }

      .trip-card {
        background: #ffffff;
        border-radius: 20px 20px 0px 0px;
        padding-top: 16px;
      }

      .trip-card-part {
        margin: 0px 16px 0px 16px;
        background: #22bbb0;
        border-radius: 16px;
        // padding-top: ;
        img {
          height: 14px;
          width: 8px;
        }

        .left-arrow {
          margin-left: 24px;
        }

        .right-arrow {
          margin-right: 24px;
        }

        .trip-pnr-number {
          font-family: $font-family_2;
          font-style: normal;
          font-weight: 400;
          font-size: 11px;
          line-height: 24px;
          color: rgba(255, 255, 255, 0.6);
          margin-bottom: 0px;

          span {
            font-weight: 700;
            color: #ffffff;
            font-size: 16px;
          }
        }

        .trip-start-end {
          background: #ffffff;
          border-radius: 16px;
          border: 1px solid #a5a5a8;
          padding: 24px 16px 24px 16px;

          .trip-destination {
            width: 16px;
            height: 87px;
          }

          .hr-line-one {
            border: 1px dashed #e8e8e9;
          }

          .hr-line-two {
            border: 1px solid #e5e5e5;
          }

          .trip-destinations {
            gap: 8px;

            .city-name {
              font-family: $font-family_1;
              font-style: normal;
              font-weight: 700;
              font-size: 18px;
              color: #1e1e26;
            }

            .city-name span {
              font-family: $font-family_1;
              font-style: normal;
              font-weight: 600;
              font-size: 12px;
              color: #a5a5a8;
            }
          }

          .passenger-name {
            font-family: $font-family_1;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 22px;
            color: #4b4b51;
          }

          .component {
            gap: 13px;
          }

          .component-img {
            height: 17px;
            width: 13px;
          }

          .component-name {
            font-family: $font-family_1;
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 22px;
            color: #4b4b51;
          }

          .luggage {
            gap: 9px;
          }

          .luggage-img {
            height: 17px;
            width: 10px;
          }

          .luggage-name {
            font-family: $font-family_1;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 22px;
            color: #78787d;
          }

          .per-guest {
            font-family: $font-family_1;
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            color: #1e1e26;
          }

          .per-guest span {
            font-weight: 600;
            font-size: 14px;
            color: #78787d;
          }
        }

        .fare-details {
          background: linear-gradient(
                          90deg,
                          rgba(34, 188, 177, 0.1) 0%,
                          rgba(143, 233, 176, 0.1) 100%
          );
          //   border: 1px solid #d2d2d4;
          //   border-radius: 0px 0px 16px 16px;
          //   border-top: none;
          padding: 15px 16px 0px 16px;

          .fare-name {
            font-family: $font-family_1;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            color: #4b4b51;
          }

          .fare {
            gap: 15px;
          }

          .fare-img {
            height: 16px;
            width: 16px;
          }

          .fare-amount {
            font-family: $font-family_1;
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            color: #1e1e26;
          }
        }
      }
    }
    .view-invoice-btn {
      font-family: $font-family_1;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      color: #00a095;
      gap: 12px;
      height: fit-content;
      border: 1px solid #22bbb0 !important;
      border-radius: 28px;
      width: 370px;
      margin: auto;
      padding: 13px 0px;
      margin-top: 16px;
    }
    .hr-line {
      background: #f4f4f4;
      margin: 0 !important;
      height: 4px;
      border-top: none;
    }
    .opinion {
      background: #fcc5c5;
      border-radius: 14px;
      padding: 16px;
      overflow: hidden;
      margin: 0 !important;

      .opinion-heading {
        font-family: $font-family_1;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        color: #1e1e26;
        width: 150px;
      }

      .opinion-help {
        font-family: $font-family_1;
        font-weight: 400;
        font-size: 12px;
        color: #4b4b51;
        padding-top: 10px;
      }
    }
    .right-side {
      .rate-us-app-store {
        font-family: $font-family_1;
        font-weight: 600;
        font-size: 16px;
        color: #1e1e26;
        padding: 12px 20px;
        gap: 8px;
        max-width: 343px;
        width: 100%;
        background: #ffffff;
        border: 1px solid #1e1e26 !important;
        border-radius: 30px;
        margin-left: 16px;
        margin-right: 16px;
      }
    }
    .rate-us {
      padding-top: 16px;
      //padding-left: 16px;
      //padding-right: 16px;

      .rate-us-heading {
        font-family: $font-family_1;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        color: #1e1e26;
      }

      .rate-us-description {
        font-family: $font-family_1;
        font-weight: 400;
        font-size: 12px;
        color: #78787d;
        max-width: 433px;
        width: 100%;
      }

      .rate-us-emoji {
        gap: 10px;
        margin-bottom: 32px;
      }

      .inActive-emoji {
        background: #f8f8f9;
        mix-blend-mode: luminosity;
        border-radius: 8px;
        width: 64px;
        height: 64px;
      }

      .active-emoji {
        background: rgba(248, 236, 78, 0.15);
        border-radius: 8px;
        width: 64px;
        height: 64px;
      }

      .feed-back-text-box {
        background: #ffffff;
        border: 1px solid #d2d2d4 !important;
        border-radius: 16px;
        max-width: 600px;
        width: 100%;
        height: 120px;
      }

      .rate-us-btn {
        font-family: $font-family_1;
        font-weight: 600;
        font-size: 16px;
        color: #ffffff;
        padding: 16px 110px;
        max-width: 343px;
        width: 100%;
        white-space: nowrap;
        background: #22bbb0;
        border-radius: 100px;
        // margin: auto;
      }

      // upload image
      .file-upload {
        background-color: #ffffff;
        max-width: 600px;
        width: 100%;
        padding: 10px 0 40px 0;
      }

      .file-upload-content {
        display: none;
      }

      .file-upload-input {
        position: absolute;
        margin: 0;
        padding: 0;
        height: 10%;
        outline: none;
        opacity: 0;
        cursor: pointer;
      }

      .image-upload-wrap {
        border: 1px dashed #d2d2d4;
        border-radius: 16px;
      }

      .image-title-wrap {
        padding: 0 15px 15px 15px;
        color: #222;
      }

      .drag-text {
        gap: 20px;

        .drag-text-heading {
          font-family: $font-family_1;
          font-weight: 600;
          font-size: 14px;
          color: #78787d;
          padding-top: 16px;
        }

        .drag-text-description {
          font-family: $font-family_1;
          font-style: normal;
          font-weight: 400;
          font-size: 11px;
          color: #a5a5a8;
        }
      }

      .drag-text img {
        margin-left: 20px;
      }
    }

    .right-side {
      background-color: #ffffff;
      padding-top: 24px;
    }
  }
  @media screen and (min-width: 1024px) {
    .completed-trip-details .trip-card {
      border-radius: 0px;
    }
    .desktop-devision-heading {
      font-family: $font-family_2;
      font-weight: 700;
      font-size: 24px;
      color: #1e1e26;
    }
    .destop-devision {
      display: flex;
      background: #ffffff;
      // border-top-left-radius: 32px;
      // border-top-right-radius: 32px;
      padding-bottom: 56px;
      gap: 33px;
      padding-left: 80px;
      padding-right: 80px;
    }
    .drag-text {
      padding-top: 5px;
      padding-bottom: 15px;
    }
    .left-side {
      width: 50%;
      margin-top: 32px;
    }
    .right-side {
      width: 50%;
      margin-top: 76px;

      .rate-us-app-store {
        font-family: $font-family_2;
        font-weight: 500;
        font-size: 18px;
        color: #00a095;
        padding: 16px 0px;
        gap: 10px;
        max-width: 356px;
        width: 100%;
        border: 1.5px solid #22bbb0 !important;
        border-radius: 100px;
      }
    }
    .completed-trip-details .trip-card-part {
      margin: 0px;
    }
    .opinion {
      max-width: 600px;
      width: 100%;
      margin-left: 0px;
      margin-right: 0px;

      background: #fcc5c5;
      border-radius: 14px;
      padding: 16px;
      overflow: hidden;

      .opinion-heading {
        font-size: 24px;
        width: 233px;
        font-family: $font-family_1;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        color: #1e1e26;

      }

      .opinion-help {
        font-family: $font-family_1;
        font-size: 18px;
        width: 211px;

        font-weight: 400;

        color: #4b4b51;
        padding-top: 10px;
      }
    }

    .rate-us {
      padding-left: 0px;
      padding-right: 0px;
      padding-top: 24px;

      .active-emoji,
      .inActive-emoji {
        height: 72px;
        width: 72px;
      }

      .feed-back-text-box {
        height: 206px;
      }

      .rate-us-heading {
        font-size: 20px;
        font-family: $font-family_1;
        font-weight: 700;
        line-height: 24px;
        color: #1e1e26;
      }

      .rate-us-description {
        font-size: 16px;
        font-family: $font-family_1;
        font-weight: 400;

        color: #78787d;
        max-width: 433px;
        width: 100%;
      }
    }

    .rate-us {

      .rate-us-emoji {
        gap: 10px;
        margin-bottom: 32px;
      }

      .inActive-emoji {
        background: #f8f8f9;
        mix-blend-mode: luminosity;
        border-radius: 8px;
        width: 64px;
        height: 64px;
      }

      .active-emoji {
        background: rgba(248, 236, 78, 0.15);
        border-radius: 8px;
        width: 64px;
        height: 64px;
      }

      .feed-back-text-box {
        background: #ffffff;
        border: 1px solid #d2d2d4 !important;
        border-radius: 16px;
        max-width: 600px;
        width: 100%;
        height: 120px;
      }

      .rate-us-btn {
        font-family: $font-family_1;
        font-weight: 600;
        font-size: 16px;
        color: #ffffff;
        padding: 16px 110px;
        max-width: 343px;
        width: 100%;
        white-space: nowrap;
        background: #22bbb0;
        border-radius: 100px;
        // margin: auto;
      }

      // upload image
      .file-upload {
        background-color: #ffffff;
        max-width: 600px;
        width: 100%;
        padding: 10px 0 40px 0;
      }

      .file-upload-content {
        display: none;
      }

      .file-upload-input {
        position: absolute;
        margin: 0;
        padding: 0;
        height: 10%;
        outline: none;
        opacity: 0;
        cursor: pointer;
      }

      .image-upload-wrap {
        border: 1px dashed #d2d2d4;
        border-radius: 16px;
      }

      .image-title-wrap {
        padding: 0 15px 15px 15px;
        color: #222;
      }

      .drag-text {
        gap: 20px;

        .drag-text-heading {
          font-family: $font-family_1;
          font-weight: 600;
          font-size: 14px;
          color: #78787d;
          padding-top: 16px;
        }

        .drag-text-description {
          font-family: $font-family_1;
          font-style: normal;
          font-weight: 400;
          font-size: 11px;
          color: #a5a5a8;
        }
      }

      .drag-text img {
        margin-left: 20px;
      }
    }
  }
}

//.completed-eco-contribution-ticket-detail {
//  background: #f0faf9;
//@media screen and (max-width: 1024px) {
//  .eco-contribution-desktop {
//    background: #f0faf9;
//  }
//  .eco-contribution {
//    .eco-heading {
//      font-family: $font-family_1;
//      font-weight: 700;
//      color: #1e1e26;
//      padding: 24px 16px 16px 16px;
//      font-style: normal;
//      font-size: 16px;
//      line-height: 24px;
//    }
//  }
//  .hr-line {
//    background: #f4f4f4;
//    margin: 0 !important;
//    height: 4px;
//    border-top: none;
//  }
//  .eco-contribution-carousel {
//    .swiper-horizontal > .swiper-pagination-bullets,
//    .swiper-pagination-bullets.swiper-pagination-horizontal,
//    .swiper-pagination-custom,
//    .swiper-pagination-fraction,
//    .swiper-button-next:after,
//    .swiper-button-prev:after {
//      display: none;
//    }
//
//    .eco-contribution-box {
//      background: #ffffff;
//      border: 1px solid rgba(0, 0, 0, 0.1);
//      box-shadow: 0px 16.3055px 30.3774px rgba(0, 0, 0, 0.0417275),
//      0px 4.85457px 9.04414px rgba(0, 0, 0, 0.0282725);
//      border-radius: 16px;
//      padding-left: 16px;
//      gap: 16px;
//      margin-left: 16px;
//      margin-bottom: 125px;
//    }
//
//    .eco-contribution-box-heading {
//      font-family: $font-family_1;
//      font-weight: 700;
//      font-size: 16px;
//      color: #1e1e26;
//      padding-top: 16px;
//    }
//
//    .eco-contribution-box-description {
//      font-family: $font-family_1;
//      font-weight: 600;
//      font-size: 14px;
//      color: #4b4b51;
//    }
//
//    .eco-contribution-box-image {
//      border-top-right-radius: 16px;
//      border-bottom-right-radius: 16px;
//    }
//  }
//}
//@media screen and (min-width: 1024px) {
//  .eco-contribution .eco-heading {
//    padding-left: 80px;
//    padding-top: 40px;
//    font-family: $font-family_2;
//    font-style: normal;
//    font-weight: 700;
//    font-size: 24px;
//    line-height: 36px;
//    color: #1E1E26;
//
//  }
//  .eco-contribution-desktop {
//    background: #f0faf9;
//  }
//  .eco-contrifution-boxes {
//    padding: 0px 80px 72px 80px;
//    gap: 30px;
//  }
//
//  .eco-contribution-box {
//    background: #ffffff;
//    border: 1px solid rgba(0, 0, 0, 0.1);
//    box-shadow: 0px 16.3055px 30.3774px rgba(0, 0, 0, 0.0417275),
//    0px 4.85457px 9.04414px rgba(0, 0, 0, 0.0282725);
//    border-radius: 16px;
//    gap: 46px;
//
//    .eco-contribution-box-heading {
//      font-family: $font-family_2;
//      font-weight: 700;
//      font-size: 20px;
//      color: #1e1e26;
//      padding-top: 24px;
//      padding-left: 24px;
//    }
//
//    .eco-contribution-box-description {
//      font-family: $font-family_1;
//      font-weight: 600;
//      font-size: 18px;
//      color: #4b4b51;
//      padding-left: 24px;
//      max-width: 202px;
//      width: 100%;
//    }
//
//    .eco-contribution-box-image {
//      border-radius: 16px;
//    }
//  }
//}
//}

.completed-eco-contribution-ticket-detail {
  background: #FFFFFF;;
  margin: 0 0 120px 0;

  hr {
    //background: #F4F4F4;
    border: 2px solid #F0FAF9;
    margin: 0 !important;

  }

  .green-miles-part {
    border: 1px solid #D2D2D4;
    max-width: 343px;
    width: 100%;
    border-radius: 16px;
    margin: 24px auto 32px auto;


    .green-miles-image {
      max-width: 342px;
      width: 100%;
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
    }

    .green-miles-data {
      padding: 16px 16px 24px 16px;
      background-color: #F0FAF9;
      border-bottom-left-radius: 16px;
      border-bottom-right-radius: 16px;
    }


    .green-miles-success {
      font-family: Ubuntu, sans-serif;
      font-size: 20px;
      font-weight: 700;
      line-height: 32px;
      color: #1e1e26;
    }

    .green-miles-earning {
      font-family: Open Sans, sans-serif;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      color: #78787D;

      span {
        font-weight: 700;
        color: #4B4B51;
      }


    }


    p {
      margin: 0;
    }


  }

  @media(min-width: 1024px) {
    background: #f0faf9;
    margin: 0;
    padding: 0 0 0 208px;
    .green-miles-confirmation {
      gap: 57px;

      .green-miles-contribution-data {
        max-width: 300px;
        width: 100%;
      }

      .green-miles-image {
        max-width: 800px;
        width: 100%;
        height: auto;
        object-fit: cover;
      }
    }
    .make-impact, .make-impact-sub {
      font-family: Ubuntu, sans-serif;
      font-size: 24px;
      font-weight: 700;
      color: #1E1E26;;
    }
    .make-impact-sub {
      font-size: 32px;
      font-weight: 700;
      padding: 24px 0 12px 0;
    }
    .greens-data {
      font-family: Open Sans, sans-serif;
      font-size: 18px;
      line-height: 28px;
      font-weight: 600;
      color: #78787D;

      span {
        color: #4B4B51;
        font-weight: 700;

      }
    }

  }

}