@import '../../assets/styles/common_styles';

.all-bus-destination {
  background-color: black;
  .main-content {
    width: 100%;
    float: left;
    padding: 0;
  }

  .main-part {
    width: 100%;
    float: left;
    padding: 38px 0 35px;
    text-align: center;
  }

  .box-area {
    width: 100%;
    float: left;
  }

  .routebox01 {
    //background-color: rgb(24, 26, 27);
    box-shadow: rgb(0 0 0 / 20%) 3px 3px 10px 0px;

    //background-color: #fff;
    padding: 16px;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    //box-shadow: 3px 3px 10px 0 rgb(0 0 0 / 20%);
    border-radius: 6px 6px 0 0;
  }

  .route-time {
    width: 100%;
    float: left;
    padding: 15px 60px 0;
  }

  h4{
    color: $teal-22BBB0;
  }

  .departure {
    width: 50%;
    float: left;
  }

  .departuretime {
    color: $teal-22BBB0;
  }

  .all-timing {
    padding: 10px 0 20px;
  }

  .arrival {
    width: 50%;
    float: left;
  }



  .box-area01 {

  }

  .routebox03 {

  }

  .routebox04 {

  }

  @media screen and (max-width: 1023px) {

    .box-area {
      display: block;
    }

    .routebox01 {
      width: 100%;
      min-height: 130px;
      margin: 0;
    }

    .navbar-wrapper {
      padding-bottom: 24px;
      background: #1E1E26;

    }
    .white-fold-padding {
      padding: 21px 16px 0
    }
    .button-border {
      border: 1px solid #1E1E26;
      border-radius: 100px;
      padding: 12px 24px;
      width: fit-content;

      p {
        white-space: nowrap;
      }
    }

  }
  @media screen and (min-width: 1024px) {
    .box-area {
      display: flex;
    }
    .routebox01 {
      width: 459px;
      min-height: 130px;
      margin: 0 45px 55px;
    }
    .navbar-wrapper {
      padding-bottom: 24px;
      background: #1E1E26;

    }
    .navigation-tab {
      background: #1E1E26;
      box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.08);
      border-radius: 100px;
      padding: 16px 24px;
    }
    .white-fold-padding {

      display: flex;
      justify-content: center;
      padding: 64px 32px 0;

      .inner-div {
        width: 100%;
        max-width: 1400px;
      }
    }
    .button-border {
      border: 1px solid #7AD6D0;
      border-radius: 100px;
      padding: 12px 24px;
      width: fit-content;

      p {
        white-space: nowrap;
      }
    }

  }
}