// 
.main-privacy-policy {
  .title {
    text-align: center;
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 56px;
    color: #1E1E26;
    padding-top: 40px;
    @media screen and (max-width: 1023px) {
      padding-top: 20px;
      font-size: 26px;
    }
  }

  .terms-condition-modal{

    @media screen and (max-width: 1024px) {
      .modal {
        left: 0;
        width: 100%;
      }
      .modal-dialog {
        margin: 0;
        height: 100%;
      }

      .modal-content {
        border-radius: 15px;
        overflow: hidden;
        height: 100%;
      }
    }
    @media screen and (min-width: 1024px) {

      .modal {
        left: 50%;
        //transform: translateX(-50%);
        transform: translate(-50%, -50%);
        width: 75vw;
        //background: white;
        height: fit-content;

        top: 50%;
        z-index: 100000;
      }
      .modal-dialog {
        margin: 0;
      }

      .modal-content {
        border-radius: 15px !important;
        overflow: hidden;
      }
    }

  }

}
.privacy-policy {
  padding: 20px 80px;
  font-family: 'Open Sans', sans-serif;
  text-align: justify;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #1E1E26;
  .custom-accordion{
    p{ 

    
    table, th, td {
        border: 2px solid black;
        text-align: center !important;
    }
    th {
      font-family: 'Open Sans', sans-serif;

      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: #1E1E26;
      margin-bottom: 8px;
    }
}
}

.content{
  
  padding: 20px 14px;
  h2{
    width: 90%;
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;
    color: var(--black-100, #1e1e26);
    font-family: "Ubuntu", sans-serif;
    font-style: normal;
    margin-bottom: 0;
  }
  h1{
     width: 80%;
     font-size: 28px;
     font-weight: 700;
     line-height: 36px;
     color: var(--black-100, #1e1e26);
     font-family: "Ubuntu", sans-serif;
     font-style: normal;
     margin-bottom: 0;
     @media screen and (max-width:768px) {
         padding-top: 50px;
     }
   }
   h3{
     font-family: "Open Sans", sans-serif;
     font-style: normal;
     font-size: 20px;
     font-weight: 700;
     line-height: 32px;
     color: var(--black-100, #1e1e26);
     margin-bottom: 0;
   }
   h4{
     font-family: "Open Sans", sans-serif;
     font-style: normal;
     font-size: 18px;
     font-weight: 600;
     line-height: 32px;
     color: var(--black-100, #1e1e26);
     margin-bottom: 0;
   }
 img{
     align-items: center;
     width:80%;
     height: 450px;
     border-radius: 3%;
     object-fit: cover;
     @media screen and (max-width: 991px) {
     height: 260px;  
     }
 }
p,ul,li{
    //  color: var(--black-100, #1e1e26);
     font-family: 'Open Sans', sans-serif;
     font-size: 16px;
     font-style: normal;
     font-weight: 400;
     line-height: 24px;
 
   }

 strong{
     font-family: "Open Sans", sans-serif;
     font-style: normal;
     font-size: 16px;
     font-weight: 700;
     line-height: 26px;
     color: var(--black-100, #1e1e26);
 }
 text{
        color: var(--black-100, #1e1e26);
        font-family: 'Open Sans', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;  
 }
 @media screen and (max-width: 1023px) {
    h2{
    width: 90%;
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    color: var(--black-100, #1e1e26);
    font-family: "Ubuntu", sans-serif;
    font-style: normal;
    margin-bottom: 5px;
  }
  h1{
     width: 80%;
     font-size: 28px;
     font-weight: 700;
     line-height: 36px;
     color: var(--black-100, #1e1e26);
     font-family: "Ubuntu", sans-serif;
     font-style: normal;
     margin-bottom: 0;
     @media screen and (max-width:768px) {
         padding-top: 50px;
     }
   }
   h3{
     font-family: "Open Sans", sans-serif;
     font-style: normal;
     font-size: 20px;
     font-weight: 700;
     line-height: 32px;
     color: var(--black-100, #1e1e26);
     margin-bottom: 0;
   }
   h4{
     font-family: "Open Sans", sans-serif;
     font-style: normal;
     font-size: 18px;
     font-weight: 600;
     line-height: 32px;
     color: var(--black-100, #1e1e26);
     margin-bottom: 0;
   }
 img{
     align-items: center;
     width:80%;
     height: 450px;
     border-radius: 3%;
     object-fit: cover;
     @media screen and (max-width: 991px) {
     height: 260px;  
     }
 }
p,ul,li{
    //  color: var(--black-100, #1e1e26);
     font-family: 'Open Sans', sans-serif;
     font-size: 14px;
     font-style: normal;
     font-weight: 400;
     line-height: 23px;
 
   }

 strong{
     font-family: "Open Sans", sans-serif;
     font-style: normal;
     font-size: 16px;
     font-weight: 700;
     line-height: 26px;
     color: var(--black-100, #1e1e26);
 }
 text{
        color: var(--black-100, #1e1e26);
        font-family: 'Open Sans', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;  
 }

}

}



  @media screen and (max-width: 1023px) {
    padding: 16px;

  }
}
