.city-details {
    background-color: #F0FAF9;
    padding: 56px 0 0;
    // margin-bottom: 15px;
    // @media screen and (max-width:767px) {
    //     margin-bottom: 15px; 
    // }

    .container {
        max-width: 1230px;

        .head-img{

            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            gap: 12px;
            align-items: center;
            margin-bottom: 12px;
      
            .icon-img{
              width: 32px;
              height: 32px;
              @media screen and (max-width: 768px) {
                width: 24px;
                height: 24px;
              }
      
            }
      
            .title{
              font-family: 'Ubuntu',sans-serif;
              font-size: 24px;
              font-weight: 700;
              line-height: 48px;
              margin-bottom: 0;
              text-align: left;
      
              @media screen and (max-width:1024px){
                  font-family: 'Ubuntu',sans-serif;
                  font-size: 20px;
                  font-weight: 600;
                  line-height: 32px;
                  text-align: left;
      
              }
            }
          }


    }
}