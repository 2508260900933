.mobile-offer {
  .bg-image {
    height: 150px;
    background-repeat: no-repeat;
    max-width: 1024px;
    background-size: 100%;
    position: relative;

    img {
      margin-top: 26px;
      margin-left: 19px;
    }
  }
  .bg-map{
    height: 277px;
    background-repeat: no-repeat;
    max-width: 1024px;
    background-size: 100%;
  }

  .logo-image {
    position: absolute;
    top: 63px;
    left: 34%;
    width:115px;
    height:115px;
    transform: translate(9%, 23px);
  }

  .offer-details {
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    color: #353A40;
    padding: 60px 30px 10px 30px;
  }
  .offer-container{
    background: #E9F8F7;
    border: 1.5px dashed #22BBB0;
    border-radius: 8px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: #1E1E26;
    margin: auto
  }

  .expire-date {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: #4B4B51;
  }

  .terms-and-condition {
    background: #FFFFFF;
    border: 1px solid #D2D2D4;
    border-radius: 16px;
    margin-left: 16px;
    margin-right: 16px;


    .terms-and-condition-heading {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: #1E1E26;
      border-bottom: 1px solid #E8E8E9;
      padding:12px ;
    }

    li {
      /* Default bullets style erased */
      list-style: none;
    }
    li::before {

      /* Unicode for a bullet */
      content: "\2022";
      /* Styles for Indentation*/
      color: #22BBB0;
      display: inline-block;
      width: 10px;
      margin-left: -1em;
    }
  }

}