 @import "../../assets/styles/font_color_mixins";
 @import "_variable.scss";

.career-page {
  background-color: #1E1E26;

  .mobile-page-title-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

  }

  .submit-button {
    background: #22BBB0;
    padding: 16px 24px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
    border-radius: 40px;

    @media screen and (max-width: 1024px) {
      min-width: 100%;
    }
    @media screen and (min-width: 1024px) {
      min-width: 253px;
    }
  }

  .banner-section {
    min-height: 80vh;

    background-color: $black-1E1E26;
    background-image: radial-gradient(circle farthest-corner at 150% 250%,
            rgba(34, 187, 176, 0.48) 0%,
            #00000000 59%);
    //background-image: url(../../assets/images/career-banner-bg.png);
    //background-position:  top -50vh left 50vw;
    //background-repeat: no-repeat;

    @media screen and (max-width: 1024px) {
      padding: 24px;
    }
    @media screen and (min-width: 1024px) {
      padding: 0 80px;
    }

    .career-banner-1,
    .career-banner-2,
    .career-banner-3{
      border-radius: 16px;
    }

    .career-banner-1 {
      @media screen and (max-width: 1024px) {
        width: 100%;
      }
      @media screen and (min-width: 1024px) {

      }
    }

    .career-banner-2 {

    }

    .career-banner-3 {

    }

    .career-banner-2-small {
      width: 100%;
    }

  }

  .Nuego-benefits {
    padding: 24px 0px;
    background: #F0FAF9;
    font-family: 'Open Sans',sans-serif;
    font-style: normal;
    margin: 32px 0px 24px 0px;

    @media (min-width: 767px) {
      margin: 64px 0px;
    }

    .head {
      font-family: 'Ubuntu',sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 32px;
      color: #1E1E26;
      margin: 0px 0px 16px 16px;

      @media (min-width: 767px) {
        margin: 56px 0px 48px 0px;
      }
    }

    .benefits-flex {
      display: grid;
      @media screen and (max-width: 1024px) {
        grid-template-columns: 1fr;
      }
      @media screen and (min-width: 1024px) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
      }

      justify-content: space-evenly;
      gap: 30px;

      @media (min-width: 767px) {
        max-width: 900px;
        width: 100%;
        margin: auto;
        gap: 0px;
      }
    }

    .plus {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0px;
      gap: 16px;
      text-align: center;

    }

    .sub-head {
      font-weight: 600;
      font-size: 18px;
      line-height: 28px;
      text-align: center;
      color: #1E1E26;
      text-align: center;
    }

    @media (min-width: 767px) {
      font-family: 'Ubuntu',sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 32px;
      text-align: center;
      color: #1E1E26;
    }
  }

  .Nuego-achievement {
    padding: 0px 16px;

    @media (min-width: 767px) and (max-width: 1100px) {
      padding: 0px 60px;
    }

    @media (min-width: 1100px) {
      padding: 0px 80px;
    }

    .head {
      font-family: 'Ubuntu',sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 32px;
      color: #1E1E26;
      padding: 32px 0px 16px 0px;

      @media (min-width: 767px) {
        font-size: 24px;
        line-height: 36px;
        text-align: center;
        padding: 48px 0px 48px 0px;
      }

    }

    .sub-head {
      font-weight: 600;
      font-size: 14px;
      line-height: 22px;
      color: #4B4B51;
      margin-top: 16px;
      max-width: 261px;

      @media (min-width: 767px) {
        font-size: 16px;
        line-height: 24px;
      }
    }

    .achieve-flex {
      display: flex;
      overflow-x: auto;
      gap: 16px;

      @media (min-width: 1024px) {
        gap: 32px;
        overflow: hidden;
      }
    }

    .achieve-flex::-webkit-scrollbar {
      display: none;
    }
    img{
      border-radius:15px;
    }
  }

  .Nuego-Opportunity {
    padding: 0px 16px;

    .content-card{
      max-width: 60%;
    }

    @media (min-width: 767px) and (max-width: 1100px) {
      padding: 0px 60px;
    }

    @media (min-width: 1100px) {
      padding: 0px 80px;
    }

    .head {
      font-family: 'Ubuntu',sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 32px;
      color: #1E1E26;
      padding: 32px 0px 16px 0px;

      @media (min-width: 767px) {
        font-size: 24px;
        line-height: 36px;
        text-align: center;
        padding: 48px 0px 48px 0px;
      }

    }

    select {
      width: 100%;
      padding: 12px 16px;
      background: #FFFFFF;
      border: 1px solid #D2D2D4 !important;
      border-radius: 8px;
    }

    .box3 {
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 12px 16px;
      background: #FFFFFF;
      border: 1px solid #D2D2D4 !important;
      border-radius: 8px;
    }

    @media (min-width: 1260px) {
      select {
        width: 100%;
        padding: 16px 100px 16px 20px !important;
      }

      .box3 {
        display: flex;
        max-width: 600px;
        width: 100%;
        padding: 16px 50px 16px 16px !important;
        justify-content: space-between;
        gap: 100px;
      }

      .box-flex {
        display: flex;
        justify-content: space-between;
        gap: 0px;
      }
    }

    @media (min-width: 900px) {
      select {
        width: 100%;

      }

      .box3 {
        display: flex;
        max-width: 600px;
        width: 100%;
        padding: 16px 16px;
        justify-content: space-between;
        gap: 100px;
      }

      .box-flex {
        display: flex;
        justify-content: space-between;
        gap: 32px;
      }

    }

    select {
      -webkit-appearance: none;
      appearance: none;
      position: relative;
      background-image: url("../../assets/icons/keyboard_arrow_downdown-facing-cavet-grey.svg");
      background-position: right 10px top 50%;
      background-size: 16px;
      background-repeat: no-repeat;
    }

    .box {
      position: relative;
    }

    select::after {
      content: "";
      background-image: url($background-cdn + "assets/images/down-arrow.png");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      width: 12px;
      height: 6px;
      top: 65%;
      right: 10%;
      position: absolute;
    }

    select:focus-visible {
      outline: none;
    }

    select option {
      font-weight: 600;
      font-size: 16px !important;
      line-height: 24px;
      color: #78787D !important;
    }

    .opportunities-grid {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      gap: 16px;

      @media (min-width: 767px) {
        grid-template-columns: repeat(2, 1fr);
      }

      @media (min-width: 1024px) {
        grid-template-columns: repeat(3, 1fr);
      }

      .opportunity-card-component {
        background: #F4FCFB;
        padding: 16px;
        border: 1px solid #E8E8E9;
        border-radius: 12px;
      }
      .applyBtn {
        padding-left: 20px;
      }
      @media screen and (max-width: 1024px) {
        .applyBtn {
          padding-left: 0px;
        }
      }
      .submit-button {
        background: #22BBB0;
        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
        border-radius: 40px;

        @media screen and (max-width: 1024px) {
          max-width: 100px;
          min-width: 100px;
        }
        @media screen and (min-width: 1024px) {
          max-width: 160px;
          min-width: 160px;
        }
      }
    }

    .no-opp-section{

      .sub-parent{
        max-width: 700px !important;
        margin: auto !important;
  
        .submit-button-no-opp {
          background: #22BBB0;
          padding: 16px 24px;
          box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
          border-radius: 40px;
          color:#FFFFFF;
      
          @media screen and (max-width: 1024px) {
            min-width: 100%;
          }
          @media screen and (min-width: 1024px) {
            min-width: 253px;
          }
        }
    
        .no-opp-sub-head {
          font-family: 'Ubuntu',sans-serif;
          font-style: normal;
          font-weight: 700;
          font-size: 20px;
          line-height: 32px;
          color: #1E1E26;
          padding: 0px 0px 16px 0px;
    
          @media (min-width: 767px) {
            font-size: 24px;
            line-height: 36px;
            text-align: center;
            padding: 8px 0px 8px 0px;
          }
    
        }
      }

    }

   

   

  }

  .job-application-component {
    @media screen and (max-width: 1024px) {

    }
    @media screen and (min-width: 1024px) {
      border: 1px solid #D2D2D4;
      border-radius: 16px;
      padding: 24px;
    }

    .job-application-form {
      max-width: 794px;
      padding: 24px;
      width: 100%;

      .upload-button {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        cursor: pointer;
      }



      

    }
  }
}