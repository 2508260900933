@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');
$background-cdn: "https://cdn.nuego.co.in/greencell/";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

button,
button:active,
button:focus {
  outline: none;
  border: none;
}

select,
select:active,
select:focus,
.input-box,
.input-box:active,
.input-box:focus,
input,
input:focus,
input:active,
textarea,
textarea:focus,
textarea:active,
button,
button:active,
button:focus {
  border: none !important;
  outline: none !important;

}

//input:focus{
//  box-shadow: 1px 1px 1px red;
//}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.swiper {
  z-index: 0 !important;
}


mark {
  background-color: transparent !important;
  font-weight: 600 !important;
  padding: 0 !important;
}

.modal {
  @media screen and (min-width: 1024px) {
    .modal-body {
      max-height: calc(100% - 3.5rem);
      overflow: auto;
    }
    & ::-webkit-scrollbar {
      width: 6px;
      height: auto;
      padding-left: 16px;
    }

    /* Track */
    & ::-webkit-scrollbar-track {
      padding-left: 16px;
      box-shadow: inset 0 0 5px transparent;
      border-radius: 10px;
    }

    /* Handle */
    & ::-webkit-scrollbar-thumb {
      background: #D2D2D450;
      border-radius: 14px;

    }

    /* Handle on hover */
    & ::-webkit-scrollbar-thumb:hover,
    & ::-webkit-scrollbar-thumb:active {
      background: #D2D2D4;
    }

  }
}

//styles for Custom Toast
#ct-container {
  .ct-row {
    .ct-group {
      .ct-toast {
        //background: linear-gradient(90deg, #AD7819 0%, #DEA64A 20.77%, #FFEB95 35.13%, #EFBC68 48.97%, #FFD67A 60.77%, #BE8D3C 74.36%, #9E6C0C 100%);
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
        border-radius: 16px;
        border: none !important;
        padding: 2px;

        svg {
          display: none;
        }

        .ct-text-group {
          background: #FFFFFF;
          //border: 1px solid #F4F4F4;
          padding: 12px;
          border-radius: 16px;

          .ct-text {
            font-size: initial;
          }
        }
      }
    }
  }
}

.offer-modal-component {
  .offer-modal {
    margin: auto;
    background-position: center;
    background-repeat: no-repeat;
    height: 150px;
  }

  .offer-logo {
    margin-top: 35px;
    margin-right: 12px;
  }

  .modal-content {
    position: relative;
    max-height: 75vh;
    min-height: 70vh;
  }

  .discount-details {
    font-family: 'Ubuntu', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    color: #1E1E26;
  }

  .expire-date {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: #FFFFFF;
    padding: 4px 12px;
    background: #EE6868;
    border-radius: 8px;
    width: 160px;
  }

  .modal-submit-button {
    width: 100%;
    padding: 16px;
    background: white;
  }

  button {
    border: none;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: #FFFFFF;
    background: #22BBB0;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
    border-radius: 40px;
    padding: 16px;
    min-width: calc(100% - 50vw);

  }

  ul li {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #4B4B51;
    padding-top: 18px;
    @media(min-width: 1280px) {
      margin-left: 52px;
    }
  }
  

}
.disable-resend-otp{
  opacity: 0.6 !important;
  cursor: not-allowed !important;
}