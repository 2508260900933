@import '../../assets/styles/font_color_mixins';
@import "_variable.scss";

.qr-component {

  .submit-button {
    background-color: $teal-22BBB0;
    color: white;
    border-radius: 100px;
    padding: 8px 16px;
  }

  .orange-wrapper {
    padding: 8px 16px;
    background: #FFF7EE;
    border: 1.5px solid rgba(239, 162, 72, 0.6);
    border-radius: 8px;
  }


  .booking-id-wrapper {
    padding: 14px 10px;
    background: #F8F8F9;
    border: 1px solid #A5A5A8;
    border-radius: 12px;

  }

  @media screen and (max-width: 1023px) {
    .wrapper {
      background-image: url($background-cdn + "assets/images/mobile-qr-bg.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      width: fit-content;
      padding: 4px;
      margin: auto auto 24px;

    }
  }

  @media screen and (min-width: 1024px) {
    .wrapper {
      background-image: url($background-cdn + "assets/images/desktop-qr-bg.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      width: fit-content;
      padding: 16px;
      margin: auto auto 24px;

      img {
        margin-bottom: 0 !important;
        width: 176px;
        height: 176px;
      }
    }
  }

}