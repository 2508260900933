.route-component{
    background-color: #353540;
    .main-container{
        width: 89%;
        padding: 32px 0;
        margin: auto;
        display:flex;
        gap: 16px;
        flex-direction: column;
        @media screen and (max-width:991px) {
            width: 100%;  
        }
       .route-heading{
        font-family: 'Ubuntu',sans-serif;
        font-size: 32px;
        font-weight: 700;
        line-height: 48px;
        text-align: left;
        color: #ffffff;
        @media screen and (max-width:991px){
            font-family: 'Ubuntu',sans-serif;
            font-size: 18px;
            font-weight: 700;
            line-height: 28px;
            text-align: left;
            width: 90%;
            margin: auto;
        }

       }
        .route-city-list{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            @media screen and (max-width:991px) {
                overflow-x: scroll;
                scroll-behavior: smooth;
                scrollbar-width: none;
                gap: 24px;
                width: 90%;
                margin: auto;
            }
            .route-item{
                color: #FFFF;
                font-family: 'Open Sans', sans-serif;
                font-size: 16px;
                font-weight: 400;
                line-height: 32px;
                text-align: center;
                width: fit-content;
                &.active {
                    color: #1E1E26;
                    border-radius: 8px;
                    border-radius: 100px;
                    padding: 5px 12px;
                    background-color: #E5F9F7;
                    font-size: 16px;
                    font-weight: 600;
                    border: 1px solid #22BBB0
                    
                  }          
                  &:hover {
                    color: #1E1E26;
                    border-radius: 100px;
                    padding: 5px 12px;
                    background-color: #E5F9F7;
                    cursor: pointer;
                    font-size: 16px;
                    font-weight: 600;
                  }
            }
        }
        .map-and-route{
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            gap: 20px;
            @media screen and (max-width:991px) {
                flex-direction: column; 
            }
            .map-section{
                width: 40%;
                @media screen and (max-width:991px) {
                    width: 90%;
                    margin: auto;
                }
                .map-image{
                    width: 100%;
                    height:100%;
                    @media screen and (max-width:991px) {
                        width: 100%;
                        height:100%;
                    }
                }
            }
            .route-section{
                    width: 45%;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    padding: 20px;
                    color: #ffffff; 
                    height: 70vh;
                    overflow-y: scroll;
                    scroll-behavior: smooth;
                    scrollbar-width: none;
                    @media screen and (max-width:991px) {
                        width: 100%;
                        height: 55vh;
                    }
                                  
                  .stop {
                    display: flex;
                    flex-direction: row;
                    align-items: flex-start;
                    margin-bottom: 40px;
                
                  .stop-content {
                    display: flex;
                    flex-direction: row;
                    gap: 20px;

                    .city-image {
                        width: 125px;
                        height: 125px;
                        object-fit: cover;
                        border-radius: 22px
                      }
                      
                      .parent{
                        display: flex;
                        flex-direction: column;
                        gap: 0px;
                        .stop-number {
                            font-family: 'Open Sans',sans-serif;
                            font-size: 14px;
                            font-weight: 600;
                            line-height: 21px;
                            text-align: left;
                            color: #22BBB0;

                            
                          }
                        .city-name {
                            font-family: 'Ubuntu', sans-serif;
                            font-size: 24px;
                            font-weight: 600;
                            line-height: 32px;
                            text-align: left;  
                            color: #ffffff;
                            @media screen and (max-width:991px) {
                                line-height: 26px;     
                            }

                          }
                          
                          .city-desc {
                            font-family: 'Open Sans', sans-serif;
                            font-size: 16px;
                            font-weight: 400;
                            line-height: 24px;
                            text-align: left;
                            @media screen and (max-width:991px) {
                                font-family: 'Open Sans', sans-serif;
                                font-size: 14px;
                                font-weight: 400;
                                line-height: 21px;
                                text-align: justify;
                            }

                          }
                      }

                  }
                  
                  &.active .stop-content .parent .city-name {
                    color: #22BBB0; 
                  }
                  
                  &.hover .stop-content .parent .city-name {
                  color: #22BBB0; 
                }
                
                }

            }

        }
    }
}