.Blog-travel-Iteneraries{
    background-color: #F0FAF9;
    border: 1px solid #D2D2D4;
    width: 100%;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 25px 0;
    //align-items: center;
    .head-subhead{
        display : flex;
        flex-direction: column;
        margin-left: 4%;
        @media screen and (max-width:768px) {
            margin-left: 2%;
    
        }
        .head{
            display:flex;
            flex-direction: row;
            gap:6px;
            align-items: center;
            .icon{
                width: 32px;
                height: 32px;
                @media screen and (max-width:768px) {
                    width: 24px;
                    height: 24px;
                }
            }
            .heading{
                font-family: 'Open Sans',sans-serif;
                font-size: 24px;
                font-weight: 700;
                line-height: 48px;
                color: var(--black-100, #1e1e26);
                @media screen and (max-width:768px) {
                    font-size: 16px;
                    line-height: 24px;
                    font-weight: 600;
                }
            }
        }
        .subhead{
            font-family: 'Open Sans',sans-serif;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            display: inline-block;
            color: #78787D;
            @media screen and (max-width:768px) {
                font-size: 14px;
                line-height: 22px;
            }
            

        }
    }
    .itineraries-cards{
        width: 93%;
        margin: auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 40px;
        
        @media screen and (max-width:768px) {
            gap: 32px 15px;
        }
        .itineraries-card{
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            width: 30%;
            height: 368px;
            border-radius: 16px;
            border: 1px solid #E8E8E9;
            background: #FFFFFF;
            gap: 20px;
            cursor: pointer;

            @media screen and (max-width:768px) {
                width: 47%;
                height: 163px;
                justify-content: space-around;
            }

            .itinerary-img{
                width: 85%;
                margin: auto;
                height: 288px;
                border-radius: 12px;
                background: #D2D2D4;
                margin-top: calc(7.5%);
                object-fit: cover;
                @media screen and (max-width:768px) {
                    height: 123px;
                }

            }
            .name-duration{
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                width: 85%;
                margin: auto;
                .name{
                    font-family: 'Open Sans',sans-serif;
                    font-size: 20px;
                    font-weight: 500;
                    line-height: 32px;
                    @media screen {
                        font-size: 14px;
                        line-height: 13.52px;

                    }
                }
                .duration{
                    font-family: 'Open Sans',sans-serif;
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 24px;
                    @media screen {
                        font-size: 12px;
                        line-height: 10.14px;
                    }
                }
            }

        }
    }
    .view-all-cta{
        margin: auto;
        width: 178px;
        height: 60px;
        //padding: 16px 0px 0px 0px;
        border-radius: 40px;
        background: #22BBB0;
        box-shadow: 0px 4px 12px 0px #00000014;
        color: #000;
        //styleName: Sub-heading 2/Web/18/Medium;
        font-family: 'Open Sans', sans-serif;
        font-size: 18px;
        font-weight: 600;
        line-height: 28px;
        text-align: center;
        color: #FFFFFF;




    }

}