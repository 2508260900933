.recommended-blogs{
    width: 100%;
    height: max-content;
    margin:auto;
    //margin-top: 24px;
    //margin-bottom: 24px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    border: 1px solid #D2D2D4;
    padding: 20px 20px 20px 20px;
    border-radius: 12px;
    //gap :10px;
    @media screen and (max-width: 450px) {
        width: 100%;
        height: max-content;
        display: flex;
        margin: 24px auto 0px;
        align-items: flex-start;
        flex-direction: column;
        border: 1px solid #D2D2D4;
        gap :10px;
        padding: 12px 0 12px 12px;
    }
    .recommended-desc-navigation{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        h2{
            font-family: "Open Sans";
            font-size: 28px;
            font-weight: 700;
            line-height: 48px;
            letter-spacing: 0em;
            text-align: left;
            margin-bottom: 0px;
            @media screen and (max-width:450px){
                        font-family: 'Open Sans' sans-serif;
                        font-size: 18px;
                        font-weight: 700;
                        line-height: 28px;
                        text-align: center;
            }
        }
            .recommended-navigation{
                width:10%;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                margin-right: 1%;
                button{
                    width:48px;
                    height:48px;
                    border-radius: 12px;
                    box-shadow: 0px 4px 14px 0px #00000017;
                    background-color: #ffff;
                    img{
                        width:7px;
                        height:14px;
                    }
                }
        }
    }
    .slider{
        width:100%;
        height: max-content;
        @media screen and (max-width: 450px){
            height:max-content;
            width: 100%;
        }
        .swiper{ 
        //width:100%;
        height:100%;
        
        .swiper-wrapper{
            //width:100%;
            height:100%;
            //border: 2px solid green;
            display: flex;
            row-gap: 20px;
            @media screen and (max-width:450px){
                width:100%;
                height:100%;
                //border: 2px solid green;
                display: flex;
                //gap: 6%;  
            }
            .swiper-slide{
                width:18%;
                height:40%;
                margin-top: 0px !important;
                @media screen and (max-width: 450px) {
                    width:30%;
                    height:40%;
                }
                .extra-blog-div {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    gap:14px;
                    img {
                      border-radius: 16px;
                      aspect-ratio : 8/7; 
                      width: 45%;
                      height: 100%;
                      object-fit:cover;
                      
                    }
                    .mweb {
                        @media screen and (max-width: 1024px) {
                          text-align: left;
                          display: flex;
                          flex-direction: column;
                          justify-content: space-evenly;
                        }  
                        h3 {
                          font-family: 'Open Sans', sans-serif;
                          font-size: 17px;
                          font-weight: 600;
                          line-height: 26px;
                          text-align: left;
                          color:  #4B4B51;

                      
                          @media screen and (max-width: 1024px) {
                            margin: 0;
                            font-family: 'Open Sans', sans-serif;
                            font-size: 16px;
                            font-weight: 500;
                            line-height: 24px;
                            text-align: left;
                          }
                          @media screen and (max-width: 768px) {
                            margin: 0;
                            font-family: 'Open Sans', sans-serif;
                            font-size: 14px;
                            font-weight: 500;
                            line-height: 20px;
                            text-align: left;
                          }
                        }
                        a {
                          display: inline-block;
                          margin-top: 10px;
                          color: #22BBB0;
                          text-decoration: none;
                          font-family: 'Open Sans',sans-serif;
                          margin: 0;
                          font-size: 16px;
                          font-weight: 500;
                          line-height: 22px;
                          @media screen and (max-width: 1024px) {       
                             font-size: 16px;
                             font-weight: 500;
                             line-height: 24px;
                             text-align: left;
                       
                           }
                        }
                      }
                  }
            }
        }
    }
    }
    .swiper-button-next:after,
    .swiper-button-prev:after {
      display: none;
    }
}