.nuego-scanner{
 width: 100%;
 margin: auto;
 height:auto;
 background: #E9F8F7;
 background: linear-gradient(100.55deg, rgba(206, 243, 251, 0) -25.34%, #E9F8F7 15.19%, #22BBB0 95.19%);
 display: flex;
 flex-direction: row;
 justify-content: space-between;
 flex-wrap: wrap;
 padding: 30px 50px;
 gap: 16px;
 position: relative;
 border-radius: 12px;

 @media screen and (max-width: 1024px) {
    padding: 15px;
    overflow: hidden;
    background: linear-gradient(170.55deg, rgba(206, 243, 251, 0) -25.34%, #E9F8F7 15.19%, #22BBB0 95.19%);
}
 .scanner-store{
    display: flex;
    flex-direction: column;
    gap: 16px;
    .download-heading{
        font-family: 'Open Sans', sans-serif;
        font-size: 24px;
        font-weight: 700;
        line-height: 36px;
        text-align: left;
        @media screen and (min-width:992px){
            width: 60%;
        }
        @media screen and (max-width:991px){
            font-size: 18px;
            font-weight: 700;
            line-height: 25px;
        }
    }
    .scanner-app-ios{
        display: flex;
        flex-direction: row;
        align-items: center;
        gap:16px;
        .scanner{
            display: flex;
            flex-direction: column;
            p{
                font-family: 'Open Sans',sans-serif;
                font-size: 14px;
                font-weight: 400;
                line-height: 36px;
                text-align: left;
                margin: 0;
                @media screen and (max-width: 1024px) {
                    font-size: 12px;
                    line-height: 22px;
 
                }
            }
            .scanner-img{
                width: 100px;
                height: 100px;
                border-radius: 6px;
                border: 2px solid #1E1E26
            }
        }
        .app-ios{
            display: flex;
            flex-direction: row;
            gap: 12px;
            @media screen and (max-width: 1024px) {
                display:flex;
                flex-direction: column;
                gap:16px;
                
              }

            img{
                width: 141px;
                height: 42px;
                @media screen and (max-width: 1024px) {
                    width: 138.03px;
                    height: 40.14px; 
                  }



            }

        }
       
    }
    .get-linked{
        width: 100%;
        display: flex;
        flex-direction: row;
        gap: 16px;
        // justify-content: space-between;
        .input-tag{
            width: 343px;
            height: 48px;
            padding: 12px 168px 12px 16px;
            border-radius: 12px ;
            background: #FFFFFF;
            border: 1px solid #D2D2D4;
        }
    }

 }

 .special-things {
    flex-direction: column;
    gap: 24px;
    margin-bottom: 32px;
    width: 100%;

    @media screen and (max-width: 1024px) {
      display:flex;
      flex-direction: column;
      
    }

    .icon-parent-banner {
        display: flex;
        align-items: center;
        gap: 12px;
        //margin-right: 24px;
        @media screen and (max-width: 1024px) {
            width: 90%;
            
          }
        img{
            width: 48px;
            height: 48px;
            @media screen and (max-width: 1024px) {
                width: 32px;
                height: 32px;
                
            }
        }
        .details-width {
            width: 165px;
            font-family: 'Open Sans', sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color: #1E1E26;

            @media screen and (max-width: 1024px) {
                font-size: 14px;
                text-align: left;
                width: 100%;
            }

          }
      }


  }

  .mobile-icon{
    position: absolute;
    width: auto;
    top:-72px;
    right: 29%;
    bottom: 0px;
    height: -webkit-fill-available;
    @media screen and (max-width:1300px) {
        width: 25%;
        top:auto;
        right: 27%;
         height: auto;
       }
    @media screen and (max-width:1024px) {
    //  width:50%; 
     top:auto;
     right: 30%;
     bottom: 0;
     height: auto;
   }
   @media screen and (max-width:596px) {
     width:50%; 
     top:auto;
     right: -30px;
     bottom: 0;
     height: auto;
   }
    // img{
    //    @media screen and (min-width:1399px){
    //      width: 33%;
    //    }

    //    @media screen and (max-width:739px) {
    //      width:100% 
    //    }
    //    @media screen and (max-width:459px) {
    //     position: absolute;
    //      width:50%;
    //      right:5%;
    //      bottom: 0px;
    //    }
    // }
   
 }
}