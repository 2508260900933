$font-family_1: 'Open Sans', sans-serif;
$font-family_2: "Ubuntu", sans-serif;

.booking-state-page {
  .main-content {
    .title {
      font-style: normal;
      font-weight: 700;
      font-size: 40px;
      line-height: 56px;
      color: #1e1e26;
      padding-top: 40px;
      @media screen and (max-width: 1023px) {
        padding-top: 20px;
        font-size: 26px;
      }
    }
    .booking-trip-part {
      margin-top: 145px;
      margin-bottom: 40px;
      img {
        width: 270px;
        height: 270px;
      }
      h2 {
        font-family:$font-family_2;
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 36px;
        color: #4b4b51;
        margin-top: 30px;
        text-align: center;
      }
      p {
        font-family: $font-family_1;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        line-height: 24px;
        text-align: center;
        color: #78787d;
      }
    }
    .bookig-trip-state {
      background: #ffffff;
      border-bottom: 1px solid #e8e8e9;
      border-radius: 20px 20px 0px 0px;
      // padding: 16px 0 48px 0;
      .inactive-trip {
        background: #ffffff;
        border-radius: 20px 20px 0px 0px;
        font-family: $font-family_1;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: #a5a5a8;
        padding-top: 24px;
        padding-bottom: 24px;
        // margin-top: -15x;
      }
      .active-trip {
        // margin-top: -15px;
        background: #f0faf9;
        font-family: $font-family_1;
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 22px;
        color: #00a095;
        padding-top: 24px;
        padding-bottom: 24px;
        border-bottom: 3px solid #22bbb0;
      }
    }

    button {
      font-family: $font-family_1;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #ffffff;
      padding: 12px 56px;
      max-width: 343px;
      width: 100%;
      background: #22bbb0;
      border-radius: 100px;
    }
  }
}

.row {
  margin-right: 0px !important;
  margin-left: 0px !important;
}

@media (min-width: 1024px) {
  .booking-state-page {
    .main-content {
      padding-left: 70px;
      padding-right: 70px;
      .desktop-booking-trip {
        margin-top: 48px;
        .inactive-trip {
          font-family:$font-family_2;
          font-style: normal;
          font-weight: 500;
          font-size: 20px;
          line-height: 32px;
          color: #a5a5a8;
          padding: 16px 107px 16px 24px;
          background-color: white;
        }
        .active-trip {
          font-family:$font-family_2;
          font-style: normal;
          font-weight: 700;
          font-size: 20px;
          line-height: 32px;
          color: #00a095;
          padding: 16px 107px 16px 24px;
          background: #f0faf9;
          border-left: 3px solid #22bbb0;
        }
        h1 {
          font-family:$font-family_2;
          font-style: normal;
          font-weight: 700;
          font-size: 24px;
          line-height: 36px;
          color: #1e1e26;
        }
      }
      .booking-trip-part {
        margin-top: 104px;
        margin-bottom: 0px;
        img {
          width: 316px;
          height: 316px;
        }
        h2 {
          font-size: 24px;
          line-height: 36px;
          color: #4b4b51;
          margin-top: 30px;
          text-align: center;
        }
        p {
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          color: #78787d;
        }
      }
      button {
        max-width: 378px;
        width: 100%;
        padding: 16px 141px;
        margin-bottom: 124px;
        margin-top: 32px;
      }
    }
  }
}

@media (min-width: 1280px) {
  .booking-state-page {
    .main-content {
      max-width: 1280px;
      width: 100%;
      margin: auto;
    }
  }
}
