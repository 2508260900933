@import "_variable.scss";

.environment-page {
  background-color: #1E1E26;

  .EnviromentPages-section {
    //background: #FFFFFF;
    padding-bottom: 40px;
    //margin-top: 60px;

    @media screen and (min-width: 1023px) {
      padding-bottom: 60px;
      //margin-top: 100px;
    }

    .EnviromentPage {
      background: #FFFFFF;
      padding: 40px 16px 0px 16px;

      @media screen and (min-width: 1023px) {
        padding: 32px 0px 80px 0px;
        max-width: 90%;
        margin: 0px auto;
      }

      .titles {
        display: flex;
        gap: 110px;
        //   @media screen and (max-width: 1023px) {
        //     padding-top: 20px;
        //     padding-bottom: 20px;
        //     font-size: 26px;
        //   }
      }

      .nextnavigation {
        @media screen and (min-width: 1023px) {
          display: none;
        }
      }

      .naturevehicleforDS {
        text-align: -webkit-center;
      }

      .Environment-title-text {
        font-family: 'Open Sans, sanserif';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #1E1E26;

        @media screen and (min-width: 1023px) {
          display: none;
        }
      }

      .Environment-title-text-ds {
        display: none;

        @media screen and (min-width: 1023px) {
          font-family: 'Open Sans',sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 22px;
          color: #A5A5A8;
          display: block;
          margin-bottom: 80px;
        }
      }

      .environmentnaturevehicle {
        margin: 40px auto;
        text-align: center;

        @media screen and (min-width: 1023px) {
          display: none;
        }
      }

      .naturevehicleforDSimage {

        display: none;

        @media screen and (min-width: 1023px) {
          display: block;
          width: 450px;
          max-height: 373px;
        }

        @media screen and (min-width: 1100px) {
          display: block;
          width: 524px;
          max-height: 373px;
        }
      }

      .green-environment {
        @media screen and (min-width: 1023px) {
          display: grid;
          grid-template-columns: 47% 53%;
          gap: 40px;
          align-items: center;
        }
      }

      .green-environment-text {
        font-family: 'Ubuntu', sans-serif;
        font-style: normal;
        font-size: 40px;
        font-weight: 700;
        line-height: 64px;
        color: #1E1E26;

        @media screen and (min-width: 1023px) {
          font-family: 'Ubuntu', sans-serif;
          font-style: normal;
          font-size: 40px;
          font-weight: 500;
          line-height: 64px;
          color: #1E1E26;
        }
      }

      span {
        color: #00A095;
      }

      .green-btns {

        padding: 5px 30px;
        margin: 24px 0px 40px 0px;
        border: 1px solid #22BBB0 !important;
        border-radius: 40px;
        font-family: 'Open Sans',sans-serif;
        background: #FFFFFF;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        text-align: right;
        color: #00A095;

        @media screen and (min-width: 1023px) {
          padding: 16px 50px;
          margin: 40px 0px 0px 0px;
          font-family: 'Open Sans',sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 28px;
          color: #00A095;
        }
      }
    }

    .Enviroment-trees-section {
      padding: 24px 16px;
      background: #F0FAF9;

      @media screen and (min-width: 1023px) {
        padding: 56px 0px;
        margin: 0px auto;

      }
    }

    .Enviroment-trees-sectionDS {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 16px;

      @media screen and (min-width: 1023px) {
        width: 80%;
        margin: 0px auto;
        gap: 130px;

      }
    }

    .tree {
      text-align: center;
    }

    .trees {
      width: 100%;
      text-align: center;
      display: block;

      @media screen and (min-width: 1023px) {
        display: none;
      }
    }

    .treesforDS {
      width: 100%;
      display: none;

      @media screen and (min-width: 1023px) {
        display: block;
      }
    }

    .Enviroment-trees-date {
      font-family: 'Open Sans',sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #78787D;

      @media screen and (min-width: 1023px) {
        font-family: 'Ubuntu',sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 32px;
        color: #78787D;
      }
    }

    .Enviroment-trees-count {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 150%;
      color: #1E1E26;

      @media screen and (min-width: 1023px) {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 700;
        font-size: 40px;
        line-height: 150%;
        color: #1E1E26;
      }
    }

    .Enviroment-trees-save {
      font-family: 'Open Sans',sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      text-transform: uppercase;
      color: #78787D;

      @media screen and (min-width: 1023px) {
        font-family: 'Open Sans',sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        text-transform: uppercase;
        color: #78787D;
      }
    }

    .Enviroment-advantage-section {
      padding: 40px 16px;

      @media screen and (min-width: 1023px) {
        width: 90%;
        padding: 100px 0px;
        margin: 0px auto;
      }
    }

    .emissionforDS-image {
      display: none;

      @media screen and (min-width: 1023px) {
        display: flex;
        justify-content: flex-end;
      }
    }

    .emissionforDS-images {
      display: none;

      @media screen and (min-width: 1023px) {
        display: flex;
        justify-content: flex-start;
      }
    }

    .emission-image {

      text-align: center;

      @media screen and (min-width: 1023px) {
        display: none;
      }
    }

    .Enviroment-ZeroEmission-section {
      @media screen and (min-width: 767px) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 30px;
        align-items: center;
      }

    }

    .Enviroment-waste-section {
      @media screen and (min-width: 767px) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 30px;
        align-items: center;
      }
    }

    .Enviroment-noise-section {
      @media screen and (min-width: 767px) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 30px;
        align-items: center;
      }
    }

    .Enviroment-eco-section {
      @media screen and (min-width: 767px) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 30px;
        align-items: center;
      }
    }

    .emissionforDS {
      display: none;

      @media screen and (min-width: 1023px) {
        display: block;
      }
    }

    .emission {
      max-width: 247px;
      height: 190px;

    }

    .emission-head {
      margin: 30px 0px 8px 0px;
      display: flex;
      align-items: center;
      gap: 10px;
      font-family: 'Ubuntu',sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 32px;
      color: #1E1E26;

      @media screen and (min-width: 1023px) {
        margin: 0px 0px 8px 0px;
        font-family: 'Ubuntu',sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 48px;
        color: #1E1E26;
      }
    }

    .textforDS {
      display: none;

      @media screen and (min-width: 1023px) {
        display: block;
      }
    }

    .text {
      display: block;

      @media screen and (min-width: 1023px) {
        display: none;
      }
    }

    .emission-para {
      font-family: 'Open Sans',sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #4B4B51;

      @media screen and (min-width: 1023px) {
        font-family: 'Open Sans',sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #4B4B51;
      }
    }

    .emission-link {
      font-family: 'Open Sans',sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #00A095;

      @media screen and (min-width: 1023px) {
        font-family: 'Open Sans',sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #00A095;
      }
    }

    .Enviroment-advantage-section {
      display: flex;
      flex-direction: column;
      gap: 25px;
    }

    .news-section {
      padding: 0px 0px 40px 16px;

      @media screen and (min-width: 1023px) {
        padding: 0px 0px 0px 80px;
      }
    }

    .news-head {
      font-family: 'Ubuntu',sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      line-height: 20px;
      color: #1E1E26;
      margin-bottom: 24px;
    }

    .newsimage1 {
      max-width: 200px;
      max-height: 197px;
    }

    .news-section-scroll-para {
      font-family: 'Open Sans',sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #4B4B51;
    }

    .news-section-scroll-link {
      margin-top: 10px;
      display: flex;
      align-items: center;
      gap: 10px;
      font-family: 'Open Sans',sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #1E1E26;
    }

    .news-section-scroll {
      display: flex;
      overflow: scroll;
      scrollbar-width: none;
      gap: 16px;
    }

    ::-webkit-scrollbar {
      width: 0px;
      height: 0px;
    }

    .swiper-button-prev {
      background-image: url($background-cdn + "assets/images/newright1.png");
      background-repeat: no-repeat;
      background-size: none;
      background-position: center;
    }

    .swiper-button-prev::after {
      display: none;
    }

    .mobilenews {
      display: block;

      @media screen and (min-width: 1023px) {
        display: none;
      }
    }

    .Dsnews {
      display: none;

      @media screen and (min-width: 1023px) {
        display: block;
        position: relative;
      }
    }

    .swiperitem {
      width: 300px;
    }

    .news-section-scroll-para-DS {
      font-family: 'Ubuntu',sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 32px;
      color: #4B4B51;
      margin-left: 15px;
    }

    .news-section-scroll-link-DS {
      font-family: 'Open Sans',sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 28px;
      color: #1E1E26;
      display: flex;
      gap: 15px;
      align-items: center;
      margin-left: 15px;
    }

    .swiper {
      margin-left: 250px;
      overflow: scroll !important;
      position: static;

    }

    .DSnewsimage1 {
      width: 100%;
    }

    .control {
      display: flex;
      align-items: center;
      z-index: 99;
      gap: 1px;
      position: absolute;
      top: 40px;
      left: -24px;
    }
  }
}
