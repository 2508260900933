.news-page{

  @media screen and (min-width:1200px){
    margin-top: 120px;
  }
  .bread-crumb{
    @media screen and (min-width:1200px){
      max-width:1200px !important;
      padding:0 !important;
      margin: auto;
    }
  }
  .section-news{
    margin: auto;
    @media screen and (min-width:1200px){
      max-width:1200px !important;
      padding:0 !important;
    }
    @media screen and (max-width:991px){
      padding:0 15px 0 !important;
    }


   
    .view-all{
  
      margin-bottom: 0;
      font-family: 'Open Sans', sans-serif;
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      @media screen and (max-width: 991px) {
        font-weight: 600;
        font-size: 14px;
        line-height: 22px;
      }
  
    }
    
    .rounded-8{
      border-radius: 8px;
    }
    .News-grid{
      
      display: grid;
      grid-template-columns: 1fr 2fr 1fr;
      grid-column-gap: 24px;
      grid-auto-columns: auto;
      @media screen and (max-width: 991px) {
        display: flex;
        flex-direction: column;
        gap: 16px;
  
        
      }
    }
  
    .image-news-istop{
      //width:311px;
      height:173px;
      object-fit: cover;
    }
    .news-heading{
      font-family: 'Open Sans', sans-serif;
      font-size: 16px;
      font-weight: 700;
      line-height: 20.8px;
      text-align: left;
      color: #1E1E26;
      margin-bottom: 0;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    .grey-text{
      color:  #78787D;
    }
    .middle-new-image{
      object-fit: cover;
    }
    .middle-new-heading{
      font-family: 'Open Sans', sans-serif;
      font-size: 24px;
      font-weight: 700;
      line-height: 32.4px;
      color: #1E1E26;
      margin-bottom: 0;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      
    }
    .middle-news-desc{
      margin-bottom: 0;
      display: -webkit-box;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    .middle-news-image{
      object-fit: cover;
    }
    .News-Emission{
      width: 100%;
      height: 162px;
      background-image: url('../../assets/images/Trees\ Saved.png');
      display: flex;
      align-items: center;
      border-radius: 8px;
  
      .Emisssion-data{
        margin: auto;
        .emission-head{
          font-family: 'Open Sans',sans-serif;
          font-size: 20px;
          font-weight: 700;
          line-height: 32px;
          color: #FFFFFF;
  
  
        }
        ul{
          display: flex;
          list-style: none;
          flex-direction: row;
          gap: 10px;
          padding: 0 !important ;
          li{
            width: 18px;
            height: 29px;
            font-family: 'Open Sans';
            font-size: 16px;
            font-weight: 700;
            line-height: 20.8px;
            text-align: left;
            height: 29px;
            width: 18px;
            color : white;
            padding: 4px;
            border-radius: 4px;
            background: linear-gradient(360deg, #193530 -17.24%, rgba(39, 179, 154, 0) 190.57%);
            border: 1px solid;
            border-image-source: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%);
          }
        }
      }
  
    }
    .news-tittle{
      font-family: 'Ubuntu ',sans-serif;
      font-size: 20px;
      font-weight: 700;
      line-height: 27px;
      margin: 0;
      color:#1E1E26;
    }
    .newsPage-image{
      width: 100%;
      height:72px;
      border-radius: 8px;
      object-fit: cover;
    }
  }
  
  .section-Announcement{
    background: #F0FAF9;
    padding: 32px 5.5%;
    @media screen and (max-width:450px) {
      padding: 10px 15px;
    }
    .announcement-heading-main{
      margin-bottom: 0;
      font-family: 'Open Sans', sans-serif;
      font-weight: 700;
      font-size: 32px;
      line-height: 48px;
      color:#1E1E26;
      @media screen and (max-width: 991px) {
        font-weight: 600;
        font-size: 21px;
        line-height: 30px;
      }
    }
    .view-all{
  
      margin-bottom: 0;
      font-family: 'Open Sans', sans-serif;
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      @media screen and (max-width: 991px) {
        font-weight: 600;
        font-size: 14px;
        line-height: 22px;
      }
  
    }
    .accordion-button::after{
      margin-left: 0 !important;
      margin-right: 25px;
    }
  
    .accordion-item{
      border-radius: 16px;
      .accordion-header{
        .accordion-button{
          .accordion-img{
            height: 100px  !important;
            width: 150px !important;
            object-fit: cover;
            @media screen and (max-width: 991px) {
              height: 70px !important;
              width: 100px !important;
              object-fit: contain;
            }
          }
         
        .announcement-heading{
          margin-bottom: 0;
          font-family: 'Ubuntu', sans-serif;
          font-weight: 600;
          font-size: 20px;
          line-height: 28px;
          color:#1E1E26;
          max-width:  800px;
  
          @media screen and (max-width: 991px) {
            font-weight: 600;
            font-size: 18px;
            line-height: 28px;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }
          } 
  
        }
  
        .accordion-button:not(.collapsed) {
          background-color: #FFFFFF;
          color:#1E1E26;
  
          .announcement-heading{
            @media screen and (max-width: 991px) {
              font-weight: 400;
              font-size: 14px;
              line-height: 28px;
              display: -webkit-box;
              -webkit-line-clamp: 4;
              -webkit-box-orient: vertical;
              overflow: hidden;
            }
            } 
        }
        .accordion-button:not(.collapsed)::after {
          background-image: url('../../assets/icons/downArrow-green.svg');
          transform: rotate(0);
          
        }
        .accordion-button::after {
          background-image: url('../../assets/icons/downArrow-green.svg');
          transform: rotate(-180deg);
          
        }
  
      }
      .accordion-body{
        font-family: 'Open Sans', sans-serif;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        // display: -webkit-box;
        // -webkit-line-clamp: 4;
        // -webkit-box-orient: vertical;
        // overflow: hidden;
  
        // @media screen and (max-width: 991px) {
        //   display: -webkit-box;
        //   -webkit-line-clamp: 3;
        //   -webkit-box-orient: vertical;
        //   overflow: hidden;
        //}
        h2{
          width: 90%;
          font-size: 24px;
          font-weight: 700;
          line-height: 36px;
          color: var(--black-100, #1e1e26);
          font-family: "Ubuntu", sans-serif;
          font-style: normal;
          margin-bottom: 0;
        }
        h1{
           width: 80%;
           font-size: 28px;
           font-weight: 700;
           line-height: 36px;
           color: var(--black-100, #1e1e26);
           font-family: "Ubuntu", sans-serif;
           font-style: normal;
           margin-bottom: 0;
           @media screen and (max-width:768px) {
               padding-top: 50px;
           }
         }
         h3{
           font-family: "Open Sans", sans-serif;
           font-style: normal;
           font-size: 20px;
           font-weight: 600;
           line-height: 32px;
           color: var(--black-100, #1e1e26);
           margin-bottom: 0;
         }
         h4{
           font-family: "Open Sans", sans-serif;
           font-style: normal;
           font-size: 18px;
           font-weight: 600;
           line-height: 32px;
           color: var(--black-100, #1e1e26);
           margin-bottom: 0;
         }
       img{
           display: none;
       }
         p,ul>li{
           color: var(--black-100, #1e1e26);
           font-family: 'Open Sans', sans-serif;
           font-size: 15px;
           font-style: normal;
           font-weight: 400;
           line-height: 24px;
       
         }

       strong{
           font-family: "Open Sans", sans-serif;
           font-style: normal;
           font-size: 16px;
           font-weight: 700;
           line-height: 26px;
           color: var(--black-100, #1e1e26);
       }
        
  
      }
    }
  
  }
  
  .mainCard-more-on-nuego,.container{
    margin-bottom: 50px;
    @media screen and (min-width:1200px){
      max-width:1200px !important;
      padding:0 !important;
    }
    .more-on-nuego-card{
      display: flex;
      width: 100%;
      height: 400px;
      
      background-repeat: no-repeat;
      background-size: auto;
      border-radius: 8px;
      align-items: end;
      @media screen and (min-width:1024px){
        background-image: url("../../assets/images/For\ Newsroom_1204\ X\ 400\ px.jpg");
      }
      @media screen and (max-width:1023px){
        background-image: url("../../assets/images/For\ Newsroom_354\ X\ 354\ px.jpg");
      }

      .more-on-nuego-sub-card{
        display: flex;
        width: 100%;
        height: 80%;
        margin: 0 auto;
        justify-content: space-between;
        flex-direction: row;
        align-items: end;
        gap: 10%;
        background: linear-gradient(360deg, rgba(0, 0, 0, 0.9) 12.16%, rgba(0, 0, 0, 0) 111.89%);
      .data-button-card{
        display: flex;
        width: 90%;
        height: fit-content;
        margin: 0 auto 20px;
        justify-content: space-between;
        flex-direction: row;
        align-items: center;
        gap: 10%;
        @media screen and (max-width:768px) {
          flex-direction: column;
          gap: 32px;
        }
        
        .more-on-nuego-text{
          width: 70%;
          font-family: 'Open Sans', sans-serif ;
          font-size: 20px;
          font-weight: 600;
          line-height: 28px;
          color: #FFFFFF;
          @media screen and (max-width:768px) {
            width: 95%;
            font-family: 'Open Sans', sans-serif ;
            font-size: 14px;
            font-weight: 400;
            line-height: 18px;
            color: #FFFFFF;
          }
        }
        .more-on-nuego-button{
          width: 20% !important;
          height: 60px;
          padding: 16px 32px 16px 32px;
          border-radius: 40px ;
          background: #22BBB0;
          box-shadow: 0px 4px 12px 0px #00000014;
  
          //styleName: Sub-heading 2/Web/18/Medium;
          font-family: 'Open Sans', sans-serif;
          font-size: 18px;
          font-weight: 600;
          line-height: 28px;
          color: #FFFFFF;
          @media screen and (max-width:768px) {
            width: 45% !important;
            font-family: 'Open Sans', sans-serif ;
            font-size: 14px;
            font-weight: 400;
            line-height: 18px;
            color: #FFFFFF;
          }
          
          
        }
  
      }
    }
    }
  }
  .env-finance-parent-card{
    // d-flex flex-row justify-content-between align-items-center
  
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  
  
    @media screen and (min-width:1200px){
      max-width:1200px !important;
    }
    @media screen and (max-width:768px) {
      flex-direction: column;
      gap: 32px;
    }
    .child-card {
      position: relative;
      display: flex;
      //flex-direction: column;
      justify-content: space-between;
      width: 48%;
      height: 328px;
      border-radius: 8px;
      //border: 1.14px solid #F6F6F6;
      border: 1px solid #22BBB0;
      @media screen and (max-width: 768px ){
        width: 100%;
        
      }
     .sub-card {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 371px;
      height: 248px;
      top: 40px;
      left: 40px;
      gap: 32px;
      z-index: 2;
      justify-content: space-between;
      @media screen and (max-width: 768px ){
        position: relative;
        top: 10px;
        left: 10px;
        width: 90%;
      }
      .heading-card{
        display: flex;
        flex-direction: column;
        gap: 12px;
  
        .teal-heading{
          font-family: 'Open Sans', sans-serif;
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
          text-align: left;
          color: #22BBB0;
        }
        .sub-heading{
          font-family: 'Open Sans', sans-serif;
          font-size: 24px;
          font-weight: 600;
          line-height: 36px;
          letter-spacing: 0.02em;
          text-align: left;
          color: #1E1E26;
        }
      }
      .card-cta-button{
        width: 174px;
        height: 60px;
        padding: 16px 32px 16px 32px;
        border-radius: 40px;
        background: #22BBB0;
        box-shadow: 0px 4px 12px 0px #00000014;
        color: #FFFFFF;
      }
     
     }
     .image-env-finance{
      position: absolute;
      width: 260px;
      height: 174px;
      bottom: 40px;
      right: 40px;
      z-index: 1;
      @media screen and (max-width:768px) {
        width: 100px;
    
      }
    }
  
    }
  
  
  }
  
  .twitterComponent-outer-card{
    width: 100%;
    height: 400px;
    background-color:  #1E1E26;
    margin-bottom: 50px;
    padding: 48px 50px 48px 50px;
    @media screen and (max-width:768px) {
      height: auto;
      padding: 48px 0px 18px 0px;
  
    }
    .container{
      .card-container{
        display: flex;
        flex-direction: row;
        gap: 2%;
        @media screen and (max-width:768px) {
          flex-direction: column;
          gap: 32px;
        }
        .child-component{
          width: 32%;
          height: 233px;
          padding: 16px;
          border-radius: 16px;
          background: #FFFFFF;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          @media screen and (max-width:768px) {
            width: 100%;
          }
          .user-Image{
            width: 56px;
            height: 56px;
            border-radius: 50%;
            overflow: hidden;
          }
          .tweeet-para{
            font-family: 'Open Sans',sans-serif;
            font-size: 15px;
            font-weight: 300;
            line-height: 19.36px;
            text-align: left;
            color: #1E1E26;
            margin-bottom: 0;
  
          }
        }
      }
    }
  }

}


.Allnews-Page{
  @media screen and (min-width:1200px){
    max-width:1200px !important;
    padding:0 !important;
    margin-top: 120px;
    margin-bottom: 50px;
  }
  .Naviagation-arrow-div{
    width: 48px;
    height: 48px;
    padding: 12px;
    border-radius : 12px;
    box-shadow: 0px 4px 14px 0px #00000017;
    background: #FFFFFF;


  }
  .swiper-button-next:after,
  .swiper-button-prev:after {
    display: none;
  }

  .swiper-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal,
  .swiper-pagination-custom,
  .swiper-pagination-fraction {
    display: none;
  }
  .advertisement-box{
    .allnews-topCardNews{
      // width: 436px;
      //height: 534px;
      display: flex;
      flex-direction: column;
      gap: 16px;
      border-radius: 8px;
      .topnewsImage{
        width: 100%;
        height: 270px;
        border-radius: 8px;
        object-fit: cover;
        @media screen and (max-width:768px){
          width: 100%;
          height: 270px;
        }

      }

     .sub-heading-desc{ 
      width: 100%;
      display: flex;
      gap: 4px;
      flex-direction: column;
      h3{
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        height: max-content;
        font-family: 'Ubuntu',sans-serif;
        font-weight: 600;
        font-size: 22px;
        line-height: 32px;
        color: #1E1E26;
        margin-bottom: 0
      }
      p{
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        font-family: 'Open Sans',sans-serif;
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        color: #1E1E26;
        margin-bottom: 0;
      }
    }

    }
  }
  .all-news-section{
  .select-filter{
    border: 1px solid #D2D2D4;
    width: 245px;
    height: 56px;
    padding: 16px;
    border-radius: 12px;
    select{
      width: 100%;
    }
  }
  .all-stories-main-div{
    display:flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap:32px;
    justify-content: space-between;
    .all-stories-single-card{
      display: flex;
      flex-direction: column;
      width: 311px;
      height: max-content;
      gap: 16px;
      border-radius: 8px;
      background: #FFFFFF;

      .allnews-single-main-image{
        width:100%;
        height:211px;
        border-radius: 8px;
      }
      .title-tag{
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        height: max-content;
        font-family: 'Ubuntu',sans-serif;
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;
        color: #1E1E26;
        margin-bottom: 0
      }

    }
  }
}

}