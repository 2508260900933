@import '../../assets/styles/font_color_mixins';
@import "_variable.scss";

.sitemap{
    .main-content{
        @media screen and (min-width: 1024px) {
          width: 100%;
          margin:auto;
          margin-top: 10%;
          
        }

        .popular-searc{

            .cont {
              overflow: hidden;
              padding: 0px 80px;
            }
            
            .subcont {
              width: 100%;
              display: flex;
              height: fit-content;
              padding: 24px;
              justify-content: center;
              align-items: center;
              border-radius: 24px;
              background-color: #1e1e26;
              //background-color: #f0faf9;
              //background: radial-gradient(46.71% 53.32% at 46.71% 50%, rgba(30, 30, 38, 0) 0%, #1E1E26 100%);
              background-image: url($background-cdn + "assets/images/homepagemap.png");
              background-repeat: no-repeat;
              background-size: cover;
              margin-bottom: 72px;
              @media  (max-width:796px) {
                margin-bottom: 40px;
              }
            }
            
            .subcont1 {
              display: flex;
              width: 90%;
              justify-content: space-between;
              align-items: center;
              flex-shrink: 0;
              height: 70%;
            }
            
            
            
            .mainbox1 {
              display: flex;
              width: 100%;
              flex-direction: column;
              justify-content: space-between;
              align-items: center;
              flex-shrink: 0;
              align-self: stretch;
            }
            
            .mainbox1 p {
              color: var(--black-100, #1e1e26);
              color:#FFFFFF;
              text-align: center;
              font-family: "Ubuntu" , sans-serif;
              font-size: 24px;
              font-style: normal;
              font-weight: 700;
              line-height: 36px;
              @media screen and (max-width:768px) {
                color: var(--black-100, #1e1e26);
                color:#FFFFFF;
                text-align: center;
                font-family: "Open Sans" , sans-serif;
                font-size: 18px;
                font-style: normal;
                font-weight: 700;
                line-height: 28px;
                
              }
            }
            
            .routs1 {
              width: 100%;
              height: 69.76%;
              display: flex;
              justify-content: space-between;
              align-items: flex-start;
              align-self: stretch;
            }
            
            
            .routs2 {
              width: 100%;
              height: 69.76%;
              margin: auto;
              display: flex;
              justify-content: space-between;
              align-items: flex-start;
              align-self: stretch;
            }
            .column1 {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              justify-content: space-between;
              height: 100%;
              width: auto;
            }
            
            .column2 {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              justify-content: space-between;
              height: 100%;
              width: auto;
            }
            
            .column3 {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              justify-content: space-between;
              height: 100%;
              width: auto;
            }

            .column4,.column5 {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              // justify-content: space-between;
              height: 100%;
              width: auto;
              gap: 30px;
            }
            
           
            
            
            
            .routs1 p {
              color: #FFFFFFCC;
              font-family: "Open Sans" , sans-serif;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 24px;
              float: left;
            }

            .routs2 p {
              color: #FFFFFFCC;
              font-family: "Open Sans" , sans-serif;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 24px;
              float: left;
            }
            
            .routeItem {
              width: 100%;
              //color: #78787d;
              font-family: "Open Sans" , sans-serif;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 24px;
              height: 24px;
            }
            
            
            
            @media (max-width: 1240px) {
            
            
              .subcont {
                width: 100%;
                box-sizing: border-box;
              }
            
              .subcont1 {
                width: 90%;
                box-sizing: border-box;
              }
            
              .mainbox1 {
                width: 67.5%;
                box-sizing: border-box;
              }
            
              .routs1 {
                width: 85%;
                height: 69.76%;
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                align-self: stretch;
              }
              
            
              .column1,
              .column3 {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: space-between;
                height: 100%;
                width: auto;
              }
            
              .routeItem p {
                font-size: 15px;
              }
            
              .svgicon {
                height: 100%;
                width: 20.2%;
              }
            
              .svgicon img {
                height: 100%;
                width: 100%;
              }
            }
            
            
            @media (max-width: 1190px){
              .routeItem p {
                font-size: 14px;
              }
            }
            
            @media (max-width: 1090px){
              .routeItem p {
                font-size: 13px;
              }
            }
            
            
            @media (max-width: 1024px) {
              .cont {
                margin-bottom: 0px;
                padding: 18px 18px 0px 18px;
                
               
              }
              // .svgicon {
              //   display: none;
              // }
              .subcont1 {
                width: 95%;
              }
              
            
              .routeItem p {
                font-size: 16px;
              }
            
              .mainbox1 {
                width: 100%;
              }
            }
            
            @media (max-width: 890px) {
              .routeItem p {
                font-size: 15px;
              }
              
            }
            
            @media (max-width: 768px) {
            
             // .popular-search{
              .cont {
                height: 100%;
                margin: 0px 10px;
                padding: 0 0;
              }
              .subcont {
                width: 100%;
                height: fit-content;
                padding: 12px;
                border-radius: 4;
                display: flex;
                flex-direction: column;
                justify-content: center;
              }
              .subcont1 {
                width: 93%;
                height: 89.2%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                box-sizing: border-box;
              }
              // .svgicon {
              //   display: none;
              // }
              .svgicon {
                display: flex;
                width: 75%;
                height: 20%;
                flex-shrink: 0;
                align-items: flex-end;
                margin: 20px 0px 20px 0px;
              }
              .svgicon img {
                width: 100%;
                height: 100%;
              }
            
              .routs1 {
                height: 95%;
                display: flex;
                flex-direction: column;
              }
              .routs2 {
                height: 400px;
                display: flex;
                flex-direction: column;
              }
            
            
            
              .column1 {
                // width: 74.8%;
                // height: 47.4%;
                width: 100%;
                height: 25.4%;
              }
              .column2 {
                // width: 74.8%;
                // height: 47.4%;
                width: 100%;
                height: 25.4%;
                
              }
            
              .column3 {
                width: 74.8%;
                height: 25.4%;
                
              }
            
              .mainbox1 {
                width: 100%;
                height: 80%;
                //padding-right: 3.5%;
            
              }
            
              .mainbox1 p {
                width: 80%;
                text-align: center;
              }
            
              .routeItem {
                width: 100%;
                
              }
            
              .routeItem p {
                width: 100%;
                color: #FFFFFFCC;
                font-family: 'Open Sans' , sans-serif;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px; 
                text-align: left;
               
              }
            //}
            }
            }

        .popular-search2{

              .cont {
                overflow: hidden;
                padding: 0px 80px;
              }
              
              .subcont {
                width: 100%;
                display: flex;
                height: 244px;
                justify-content: center;
                align-items: center;
                border-radius: 24px;
                background-color: #1E1E26;
                //background: radial-gradient(46.71% 53.32% at 46.71% 50%, rgba(30, 30, 38, 0) 0%, #1E1E26 100%);
                background-image: url($background-cdn + "assets/images/homepagemap.png");
                background-repeat: no-repeat;
                background-size: cover;
                margin-bottom: 72px;
                @media  (max-width:796px) {
                  margin-bottom: 40px;
                }
              }
              
              .subcont1 {
                display: flex;
                width: 90%;
                justify-content: space-between;
                align-items: center;
                flex-shrink: 0;
                height: 70%;
              }
              
              .mainbox1 {
                display: flex;
                width: 100%;
                flex-direction: column;
                justify-content: space-between;
                align-items: flex-start;
                flex-shrink: 0;
                align-self: stretch;
              }
              
              .mainbox1 p {
                color: var(--black-100, #1e1e26);
                color:#FFFFFF;
                text-align: center;
                font-family: "Ubuntu" , sans-serif;
                font-size: 24px;
                font-style: normal;
                font-weight: 700;
                line-height: 36px;
                @media screen and (max-width:768px) {
                  color: var(--black-100, #1e1e26);
                  color:#FFFFFF;
                  text-align: center;
                  font-family: "Open Sans" , sans-serif;
                  font-size: 18px;
                  font-style: normal;
                  font-weight: 700;
                  line-height: 28px;
                  
                }
              }
              
              .routs1 {
                width: 100%;
                height: 69.76%;
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                align-self: stretch;
              }
              
              .column1 {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: space-between;
                height: 100%;
                width: auto;
              }
              
              .column2 {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: space-between;
                height: 100%;
                width: auto;
              }
              
              .column3 {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: space-between;
                height: 100%;
                width: auto;
              }
              
              .routs1 p {
                color: #FFFFFFCC;
                font-family: "Open Sans" , sans-serif;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
                float: left;
              }
              
              .routeItem {
                width: 100%;
                //color: #78787d;
                font-family: "Open Sans" , sans-serif;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
                height: 24px;
              }
              
              
              
              @media (max-width: 1240px) {
              
              
                .subcont {
                  width: 100%;
                  box-sizing: border-box;
                }
              
                .subcont1 {
                  width: 90%;
                  box-sizing: border-box;
                }
              
                .mainbox1 {
                  width: 67.5%;
                  box-sizing: border-box;
                }
              
                .routs1 {
                  width: 85%;
                  height: 69.76%;
                  display: flex;
                  justify-content: space-between;
                  align-items: flex-start;
                  align-self: stretch;
                }
              
                .column1,
                .column2,
                .column3 {
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                  justify-content: space-between;
                  height: 100%;
                  width: auto;
                  margin: auto;
                }
              
                .routeItem p {
                  font-size: 15px;
                }
              
                .svgicon {
                  height: 100%;
                  width: 20.2%;
                }
              
                .svgicon img {
                  height: 100%;
                  width: 100%;
                }
              }
              
              
              @media (max-width: 1190px){
                .routeItem p {
                  font-size: 14px;
                }
              }
              
              @media (max-width: 1090px){
                .routeItem p {
                  font-size: 13px;
                }
              }
              
              
              @media (max-width: 1024px) {
                .cont {
                  margin-bottom: 0px;
                  padding: 18px 18px 0px 18px;
                  
                 
                }
                // .svgicon {
                //   display: none;
                // }
                .subcont1 {
                  width: 95%;
                }
                
              
                .routeItem p {
                  font-size: 16px;
                }
              
                .mainbox1 {
                  width: 100%;
                }
              }
              
              @media (max-width: 890px) {
                .routeItem p {
                  font-size: 15px;
                }
                
              }
              
              @media (max-width: 768px) {
              
               // .popular-search{
                .cont {
                  height: 100%;
                  margin: 0px 10px;
                  padding: 0 0;
                }
                .subcont {
                  width: 100%;
                  height: 588px;
                  border-radius: 4;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                }
                .subcont1 {
                  width: 93%;
                  height: 89.2%;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  box-sizing: border-box;
                }
                // .svgicon {
                //   display: none;
                // }
                .svgicon {
                  display: flex;
                  width: 75%;
                  height: 20%;
                  flex-shrink: 0;
                  align-items: flex-end;
                  margin: 20px 0px 20px 0px;
                }
                .svgicon img {
                  width: 100%;
                  height: 100%;
                }
              
                .routs1 {
                  height: 95%;
                  display: flex;
                  flex-direction: column;
                }
              
              
              
                .column1 {
                  // width: 74.8%;
                  // height: 47.4%;
                  width: 74.8%;
                  height: 25.4%;
                }
                .column2 {
                  // width: 74.8%;
                  // height: 47.4%;
                  width: 74.8%;
                  height: 25.4%;
                  
                }
              
                .column3 {
                  width: 74.8%;
                  height: 25.4%;
                  
                }
              
                .mainbox1 {
                  width: 100%;
                  height: 80%;
                  //padding-right: 3.5%;
              
                }
              
                .mainbox1 p {
                  width: 80%;
                  text-align: center;
                }
              
                .routeItem {
                  width: 100%;
                  
                }
              
                .routeItem p {
                  width: 100%;
                  color: #FFFFFFCC;
                  font-family: 'Open Sans' , sans-serif;
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 24px; 
                  text-align: left;
                 
                }
              //}
              }
              }
      }
}