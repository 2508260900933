.tour-container {
  background: #1e1e26;
  padding: 32px 0 0 0;
  position: relative;
  min-height: 1500px;
  .tour-part {
    position: relative;
  }
  .title {
    padding: 0 94px 32px 84px;
    font-family: "Ubuntu", sans-serif;
    font-size: 18px;
    font-weight: 700;
    line-height: normal;
    color: #ffffff;
    text-align: left;
    margin: 0;
  }
  .sub-container {
    margin: 0 0 0 86px;
  }
  .sub-container-title,
  .sub-container-right-title {
    font-family: "Ubuntu", sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: normal;
    color: #fff;
    margin: 0 0 28px 0;
  }
  .tour-img-section {
    display: flex;
    gap: 16px;
    max-width: 300px;
    width: 100%;
    overflow: scroll;
    margin: 0 auto 80px auto;
    padding: 0 24px 0 0;
  }
  .tour-img-section::-webkit-scrollbar {
    display: none;
  }
  .tour-place {
    width: 226px;
    height: 390px;
    border-radius: 16px;
  }
  .reaching {
    font-family: "Ubuntu", sans-serif;
    font-size: 18px;
    font-weight: 700;
    line-height: normal;
    text-align: center;
    color: #d2d2d4;
    margin: 0 0 7px 0;
  }
  .location {
    font-family: "Ubuntu", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    text-align: center;
    color: #ffffff66;
    margin: 0;
  }
  .tour-btm-img {
    margin: 32px auto 0 auto;
    display: flex;
    width: 262px;
    height: 130px;
  }
  .number {
    font-family: "Ubuntu", sans-serif;
    font-size: 12px;
    font-weight: 500;
    line-height: normal;
    text-align: center;
    margin: 4px 0;
    color: #fff;
  }
  .location-name,
  .location-name-first {
    font-family: "Ubuntu", sans-serif;
    font-size: 12px;
    font-weight: 500;
    line-height: normal;
    text-align: center;
    margin: 0 0 9px 0;
    color: #fff;
  }
  .location-name-first {
    color: #00b1a0;
  }
  .road-ds-img {
    width: 84px;
    height: 390px;
  }
  .road-img-part {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 2.5%;
    left: 0%;
    transform: translate(0%, 0%);
  }
  .road-top-img {
    width: 127px;
    height: 115px;
  }
  .tour-mb-btm {
    width: 86px;
    height: 415px;
  }

  .bus-img {
    width: 88px;
    height: 190px;
    position: absolute;
    left: 0%;
    float: left;
    margin: 70px 0 0 0;
    z-index: 10;
  }

  .km-cover {
    font-family: "Open Sans", sans-serif;
    font-size: 10px;
    font-weight: 400;
    line-height: normal;
    color: #1e1e26;
    background-color: white;
    border-radius: 0 8px 0 0;
    width: 83px;
    padding: 2px 12px;
    white-space: nowrap;
    margin: 0;
  }

  .km {
    background: #f8ec4e;
    font-family: "Open Sans", sans-serif;
    font-size: 12px;
    font-weight: 700;
    line-height: normal;
    color: #1e1e26;
    width: 83px;
    padding: 2px 12px;
    border-radius: 0 0 8px 0;
    margin: 0;
    span:last-child {
      font-weight: 500;
    }
  }
  .km-part {
    float: left;
  }

  &.sticky-active {
    .bus-sticky {
      position: sticky;
      top: 1px;
      z-index: 1000;
    }
    .km-part {
      position: sticky;
      top: 18px;
      z-index: 1000;
      float: left;
    }
  }
  video {
    border-radius: 16px;
  }
  .video-player-part {
    position: relative;
    .player-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .img-below-text {
    font-family: "Ubuntu", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    color: #fff;
  }
  .tour-place-img {
    position: relative;
    p {
      background: #f8ec4e;
      margin: 0;
      font-family: "Ubuntu", sans-serif;
      font-size: 8px;
      font-weight: 700;
      line-height: normal;
      padding: 1px 4px;
      width: fit-content;
      position: absolute;
      top: 25px;
      left: 46.4%;
      transform: translate(-46.4%, 0%);
    }
  }

  @media (min-width: 992px) {
    padding: 80px 0 0 0;

    .tour-part {
      max-width: 1089px;
      width: 100%;
      margin: 0 auto;
    }
    .km-part {
      float: right;
      top: 103px;
    }
    .km-cover {
      font-size: 14px;
      width: 124px;
      border-radius: 8px 0 0 0;
    }

    .km {
      font-size: 18px;
      width: 124px;
      border-radius: 0 0 0 8px;
      span:last-child {
        font-size: 14px;
      }
    }

    .title {
      font-size: 40px;
      padding: 0 0 80px 0;
    }
    .sub-container {
      width: fit-content;
      margin: 0;
    }
    .right-space {
      margin: 0 240px 0 0;
    }
    .sub-container-title,
    .sub-container-right-title {
      font-size: 24px;
      width: 465px;
    }
    // .sub-container-title {
    //   color: #00b1a0;
    // }
    .sub-container-right-title {
      margin: 0 0 28px auto;
    }
    .tour-img-section {
      max-width: 100%;
      overflow: visible;
      margin: 0 auto 100px auto;
      padding: 0;
    }
    .reaching {
      font-size: 28px;
      margin: 0 0 12px 0;
    }
    .location {
      font-size: 24px;
    }
    .tour-btm-img {
      width: 400px;
      height: 200px;
    }
    .img-below-text {
      font-size: 16px;
    }
    .road-img-part {
      top: 29%;
      left: 50%;
      transform: translate(-50%, -30%);
    }
    .road-ds-img {
      width: 145px;
      height: 396px;
    }

    .road-ds-diff-img {
      width: 127px;
      height: 380px;
    }

    .number {
      font-size: 24px;
      margin: 9px 0;
    }
    .location-name,
    .location-name-first {
      font-size: 24px;
    }
    .location-name-first {
      color: #00b1a0;
    }

    .bus-img {
      width: 130px;
      height: 270px;
      left: 50%;
      transform: translate(-50%, -15%);
      margin: 220px 0 0 0;
    }

    &.sticky-active {
      .km-part {
        float: right;
        top: 103px;
      }
    }
    .tour-place-img {
      p {
        font-size: 12px;
        top: 39px;
        left: 47%;
        transform: translate(-47%, 0%);
      }
    }
  }

  @media (min-width: 1300px) {
    .tour-part {
      max-width: 1267px;
    }
  }
}
