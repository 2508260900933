.mobilebottomnav{
  background-color: transparent;
  position: fixed;
  width: 100%;
  bottom: -1px;
  ;
  .dark-fold{

    padding: 18px 27px 12px 27px;
    background-color: rgba(30, 30, 38, 1);
    border-radius: 24px 24px 0 0;
  }

  //position: relative;

  .mark_hr{
    background: #10C3BF;
    width: 39px;
    height: 3.78px;
    position: absolute;
    margin: 0;
    top: -20px;
    left: 50%;
    transform: translateX(-50%);
  }
  .para{
    font-family: 'Open Sans',sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 22px;
    color: #FFFFFF;
    margin-top: 4px;
    white-space: nowrap;
  }
  .calender_icon{
    //width: 56px;
    //height: 56px;
    background: rgba(34, 187, 176, 1);
    border-radius:100% ;
    padding: 18px 19px;
    margin-top: -55px;
  }
  .gap-18{
    gap: 18px;
    .home,.booking,.calender,.support,.profile{
      text-align: center;
      width: 20%;
      position: relative;
    }
  }
}